import React, { FC, useState, ChangeEvent } from 'react';
import { Input } from '.';

import './InputTags.style.scss';
import { CloseSvg } from '../../SVG/Close';

type Props = {
	tags: string[];
	setTags: (tags: string[]) => void;
};

export const InputTags: FC<Props> = ({ tags, setTags }) => {
	const [value, setValue] = useState('');

	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter' && value !== '') {
			e.preventDefault();
			setTags([...tags, value]);
			setValue('');
		}
	};

	const handleDeleteTag = (index: number) => {
		const arr = tags;
		arr.splice(index, 1);
		setTags([...arr]);
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.currentTarget.value;
		inputValue.split(',').forEach((tag, index) => {
			if (inputValue.split(',').length === index + 1) {
				setValue(tag);
			} else {
				setTags([...tags, tag.trim()]);
			}
		});
	};

	return (
		<div className="relative">
			<Input
				value={value}
				onChange={handleChange}
				placeholder={'tags'}
				label={'tags'}
				onKeyPress={handleKeyPress}
			/>
			<div className="TagsWrapper">
				{tags.map((tag, index) => (
					<div key={`${tag}-${index}`} className="Tag">
						<div style={{ whiteSpace: 'nowrap' }}>{tag}</div>
						<div
							className="TagClose"
							onClick={() => handleDeleteTag(index)}>
							<CloseSvg />
						</div>
					</div>
				))}
			</div>
		</div>
	);
};
