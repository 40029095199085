import React, {FC} from 'react';
import classnames from 'classnames';

import './Message.style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { MappedProps } from '../../containers/Messages';

export const Messages: FC<MappedProps> = ({
    reset,
    messages
}) => {

    return (
        <div className={classnames("Message", {
            hidden: !messages.show,
            [messages.type]: true
        })}>
            <div className="MessageContent">{messages.value}</div>
            <div className="MessageCloseIcon"><FontAwesomeIcon icon={faTimes} cursor="pointer" onClick={reset}/></div>
        </div>
    );
}
