import React, { FC, useState, useRef, useEffect, useCallback } from 'react';
import WaveSurfer from 'wavesurfer.js';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';

// @ts-ignore
import * as WaveSurferRegions from 'wavesurfer.js/dist/plugin/wavesurfer.regions.js';
import './AudioPlayer.style.scss';
import { useDispatch } from 'react-redux';
import { pushMessage } from '../../actions/messages';
import { InputNumber } from '../Shared/Input/InputNumber';

type Props = {
	url: string;
	handleSetStart: (start: number) => void;
	handleSetDuration: (duration: number) => void;
	onPlay?: () => void;
	onPause?: () => void;
	start: number;
	duration: number;
};

const toHHMMSSmmm = (duration: number) => {
	let hours = Math.floor(duration / 3600);

	let minutes = Math.floor((duration - hours * 3600) / 60);
	let seconds = Math.floor(duration - hours * 3600 - minutes * 60);
	let ms = Math.ceil((duration % 1) * 1000);
	let displayMin = `${seconds}`;

	let displaySecondes = `${seconds}`;
	let displayMs = `${ms}`;

	if (minutes < 10) {
		displayMin = displayMin = '0' + minutes;
	}
	if (seconds < 10) {
		displaySecondes = '0' + seconds;
	}
	if (ms < 100) {
		displayMs = `0${ms}`;
	}
	if (ms < 10) {
		displayMs = `00${ms}`;
	}
	return displayMin + '.' + displaySecondes + '.' + displayMs;
};

export const AudioPlayer: FC<Props> = ({
	url,
	start,
	duration,
	handleSetDuration,
	handleSetStart,
	onPlay,
	onPause,
}) => {
	const [played, setPlayed] = useState(false);
	const [currentTime, setCurrentTime] = useState(0);
	const [w, setWavesrfer] = useState<WaveSurfer | null>(null);
	const [audioDuration, setAudioDuration] = useState(0);
	const div = useRef<HTMLElement>();

	const dispatch = useDispatch();

	useEffect(() => {
		try {
			if (div.current) {
				const wavesurfer = WaveSurfer.create({
					container: div.current,
					waveColor: 'rgba(0, 0, 0, 0.3)',
					progressColor: '#111',
					barWidth: 3,
					barRadius: 2,
					scrollParent: false,
					cursorColor: '#FFEB74',
					splitChannels: false,
					mediaControls: true,
					forceDecode: true,
					responsive: true,
					height: 100,
					plugins: [
						WaveSurferRegions.create({
							regions: [
								{
									start: start < 0 ? 0 : start,
									end: (start < 0 ? 0 : start) + duration,
									loop: true,
								},
							],
						}),
					],
				});

				wavesurfer.on('ready', () => {
					const d =
						(wavesurfer.backend as any)?.buffer.duration ||
						audioDuration;

					setAudioDuration(d);

					if (start + duration > d) {
						if (start > d) {
							handleSetStart(0);
						} else handleSetDuration(d - start);
					}
					wavesurfer?.play();
				});

				wavesurfer.on('play', () => {
					setPlayed(true);
					onPlay?.();
				});

				wavesurfer.on('pause', () => {
					setPlayed(false);
					onPause?.();
				});

				wavesurfer.on('audioprocess', time => {
					setCurrentTime(time);
				});

				wavesurfer.on('seek', () => {
					setCurrentTime(wavesurfer.getCurrentTime());
				});

				wavesurfer.on('region-update-end', e => {
					if (e.start < 0) {
						wavesurfer?.clearRegions?.();
						wavesurfer?.addRegion?.({
							start: start,
							end: e.end,
							loop: true,
						});
						handleSetStart(0);
						handleSetDuration(e.end);
					} else {
						handleSetStart(e.start);
						handleSetDuration(e.end - e.start);
					}
				});

				wavesurfer.on('region-updated', e => {
					if (e.start < 0) {
						handleSetStart(0);
						handleSetDuration(e.end);
					} else {
						handleSetStart(e.start);
						handleSetDuration(e.end - e.start);
					}
				});

				wavesurfer.load(url);

				setWavesrfer(wavesurfer);

				return () => {
					if (wavesurfer) {
						wavesurfer.stop();
						wavesurfer.destroy();
					}
				};
			}
		} catch (error) {
			dispatch(pushMessage(error.message, 'error'));
		}
	}, [div.current]);

	const handleClickPlayButton = () => {
		try {
			w?.playPause();
		} catch (error) {
			dispatch(pushMessage(error.message, 'error'));
		}
	};

	const durationOnChange = (d: number) => {
		try {
			if (start + d <= audioDuration) {
				w?.clearRegions?.();
				w?.addRegion?.({
					start: start,
					end: start + d,
					loop: true,
				});
				handleSetDuration(d);
			} else {
				handleSetDuration(duration + 0.00000000000001);
			}
		} catch (error) {
			dispatch(pushMessage(error.message, 'error'));
		}
	};

	const startOnChange = (s: number) => {
		try {
			if (s + duration <= audioDuration) {
				w?.clearRegions?.();
				w?.addRegion?.({
					start: s,
					end: s + duration,
					loop: true,
					color: 'rgba(0, 0, 0, 0.3)',
				});
				handleSetStart(s);
			} else {
				handleSetStart(start + 0.00000000000001);
			}
		} catch (error) {
			dispatch(pushMessage(error.message, 'error'));
		}
	};
	const isTooLong = duration > 15;

	return (
		<>
			<div className="AudioPlayer">
				<div style={{ marginRight: 15 }}>
					<FontAwesomeIcon
						icon={played ? faPause : faPlay}
						onClick={handleClickPlayButton}
						cursor={'pointer'}
					/>
				</div>
				<div style={{ width: '100%' }}>
					<div className="flex">
						<div
							className="AudioPlayerTime"
							style={{ border: 'none' }}>
							<span>now playing: {toHHMMSSmmm(currentTime)}</span>
						</div>
					</div>
					<div
						style={{ marginTop: 10 }}
						className={classnames({
							AudioPlayerError: isTooLong,
						})}>
						<div id="waveform" ref={div as any}></div>
					</div>
					<div className="flex space-between">
						<div className="AudioPlayerTime">
							<span>
								start:{' '}
								<InputNumber
									value={start}
									onChange={startOnChange}
								/>
							</span>
						</div>
						<div
							className={classnames('AudioPlayerTime', {
								AudioPlayerError: isTooLong,
							})}>
							<span>
								duration:{' '}
								<InputNumber
									value={duration}
									onChange={durationOnChange}
								/>
							</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
