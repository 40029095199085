import React, { FC } from 'react';
import classnames from 'classnames';
import './Stepper.style.scss';
import { Link } from 'react-router-dom';

type Props = {
	steps: Array<{
		value: string | number;
		link?: string;
	}>;
	currentIndex: number;
};

export const Stepper: FC<Props> = ({ currentIndex, steps }) => {
	return (
		<div className="Stepper">
			{steps.map((step, index) => {
				const isDisable = index > currentIndex;
				const isLast = index + 1 !== steps.length;
				return (
					<div
						key={`Stepper-${index}`}
						className={classnames('flex align-center', {
							disable: isDisable,
						})}>
						{step.link ? (
							<Link to={step.link}>{step.value}</Link>
						) : (
							<div>{step.value}</div>
						)}
						{isLast ? <div className="StepperSeparator" /> : null}
					</div>
				);
			})}
		</div>
	);
};
