import { Messages } from '../components/Message';
import { connect } from 'react-redux';
import { ApplicationState } from '../reducers';
import { ThunkDispatch } from 'redux-thunk';

import {
	push,
    reset,
	Action as MessagesActions
} from '../actions/messages';

// #region typings

export type MappedProps = ReturnType<typeof mapStateToProps> &
	ReturnType<typeof mapDispatchToProps>;

// #endregion
// #region public methods

const mapStateToProps = ({ messages }: ApplicationState) => ({
	messages
});

const mapDispatchToProps = (
	dispatch: ThunkDispatch<ApplicationState, any, MessagesActions>
) => ({
	push: (value: string) => dispatch(push(value)),
	reset: () => dispatch(reset())
});

// #endregion
// #region public methods

export default connect(mapStateToProps, mapDispatchToProps)(Messages);

// #endregion
