import React, { FC, useState } from 'react';
import 'firebase/auth';
import { MainLayout, MainLeftLayout, MainRightLayout } from '../Layouts/Main';
import { Stepper } from '../Shared/Stepper';
import '../App/App.styles.scss';
import './Create.style.scss';
import { FileUploader } from '../Shared/FileUploader';
import { Button } from '../Shared/Button';
import { CustomFile, Asset } from '.';
import { post } from '../../../modules/axios';
import { auth } from 'firebase';
import { useHistory } from 'react-router-dom';
import { ReadMore } from '../Shared/ReadMore';

type Props = {
	handleUploadFile: (file: Asset | null) => void;
};

export const UploadGif: FC<Props> = ({ handleUploadFile }) => {
	const [loading, setLoading] = useState(false);
	const [file, setFile] = useState<CustomFile | null>(null);

	const history = useHistory();

	const handleSubmit = async () => {
		if (file) {
			const token = await auth().currentUser?.getIdToken();
			const formData = new FormData();
			formData.append('file', file.data);

			setLoading(true);
			return post<Asset>('/api/assets', {
				headers: {
					'Content-Type': 'multipart/form-data',
					authorization: `Bearer ${token}`,
				},
				data: formData,
			})
				.then(asset => {
					handleUploadFile(asset);
					history.push('/create/2');
				})
				.catch(e => alert(e))
				.finally(() => setLoading(false));
		}
	};

	return (
		<MainLayout>
			<MainLeftLayout>
				<div className="DashboardLeftWrapper">
					<div className="DashboardLeftNumber">1</div>
					<div className="DashboardLeftContent">
						<div className="DashboardLeftTitle">
							<span>
								start by
								<br />
								adding
								<br />
								your GIF.
							</span>
						</div>
						<div className="DashboardLeftDescription">
							<ReadMore>
								We recommend uploading GIFs under 5 MB, and
								close to 1080 x 1080 px. Learn more about how to{' '}
								<a
									href="https://www.emojam.com/guidelines"
									rel="noopener noreferrer"
									target="_blank">
									make audio GIFs perfect
								</a>
								.
							</ReadMore>
						</div>
					</div>
				</div>
			</MainLeftLayout>
			<MainRightLayout>
				<div className="flex flex-end top-25">
					<Stepper
						steps={[
							{ value: 1 },
							{ value: 2 },
							{ value: 3 },
							{ value: 4 },
						]}
						currentIndex={0}
					/>
				</div>
				<div className="FileUploadWrapper">
					<FileUploader
						file={file}
						handleDeleteFile={() => setFile(null)}
						handleLoadFile={setFile}
					/>
					<div className="ButtonsWrapper">
						<Button
							size="normal"
							onClick={handleSubmit}
							disabled={file === null || loading}>
							{loading ? 'uploading' : 'next'}
						</Button>
					</div>
				</div>
			</MainRightLayout>
		</MainLayout>
	);
};
