import React, { FC, useState, useEffect } from 'react';
import 'firebase/auth';
import '../App/App.styles.scss';
import './Create.style.scss';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { UploadGif } from './UploadGif';
import { UploadMusic } from './UploadMusic';
import { Details } from './Details';
import { post } from '../../../modules/axios';
import { auth, analytics } from 'firebase';
import { End } from './End';
import { EndLinks } from './EndLinks';
import { TrimMusic } from './TrimMusic';

export type Music = {
	artist: string;
	title: string;
	url: string | File | Asset;
	link: string;
};

export type CustomFile = {
	data: File;
	src: string;
};

export type Asset = {
	id: string;
	url: string;
};

const beforeUnload = (e: BeforeUnloadEvent) => {
	e.preventDefault();
	e.returnValue = '';
};

export const Create: FC = () => {
	const [file, setFile] = useState<Asset | null>(null);
	const [music, setMusic] = useState<Music | null>(null);
	const [emojam, setEmojam] = useState<any | null>(null);
	const [start, setStart] = useState(0);
	const [duration, setDuration] = useState(10);

	const history = useHistory();

	const handleClear = () => {
		setFile(null);
		setMusic(null);
		setEmojam(null);
		setStart(0);
		setDuration(10);
	};

	const handleSubmit = async (
		title: string,
		source: string,
		tags: string[],
		type: 'emojam' | 'video' | 'nft',
		visibility: 'private' | 'public' | 'members'
	) => {
		if (music && file) {
			const token = await auth().currentUser?.getIdToken();

			const formData = new FormData();
			formData.append('file', file.id);
			formData.append('audio', (music.url as Asset).id);
			formData.append('name', title);
			formData.append('source', source);
			formData.append('tags', JSON.stringify(tags));
			formData.append('start', start.toString());
			formData.append('duration', duration.toString());
			formData.append('type', type);
			formData.append('visibility', visibility);

			if (music.link) {
				formData.append('sponsored_link', music.link);
			}

			return post('/api/emojams/create', {
				headers: {
					'Content-Type': 'multipart/form-data',
					authorization: `Bearer ${token}`,
				},
				data: formData,
			})
				.then(emojam => {
					setEmojam(emojam);
					analytics().logEvent('emojamCreate', {
						audioType: typeof music.url === 'string' ? 0 : 1,
					});
					history.push('/create/5');
				})
				.catch(e => alert(e));
		}
	};

	useEffect(() => {
		const pushAnalyticsEvent = () => {
			if (!emojam) {
				let step = 0;
				analytics().logEvent('emojamStopCreate', {
					step,
				});
			}
		};

		window.onbeforeunload = () => {
			pushAnalyticsEvent();
		};

		window.addEventListener('beforeunload', beforeUnload);

		return () => {
			window.removeEventListener('beforeunload', beforeUnload);
			pushAnalyticsEvent();
		};
	}, []);

	return (
		<Switch>
			<Route path={'/create/1'}>
				<UploadGif handleUploadFile={setFile} />
			</Route>

			{!file && <Redirect to={'/create/1'} />}
			<Route path={'/create/2'}>
				<UploadMusic
					file={(file as Asset)?.url}
					music={music}
					handleUploadMusic={setMusic}
					handleSetStart={setStart}
					handleSetDuration={setDuration}
					start={start}
					duration={duration}
				/>
			</Route>

			{(!file || !music) && <Redirect to={'/create/2'} />}
			<Route path={'/create/3'}>
				<TrimMusic
					fileSrc={(file as Asset)?.url}
					music={music as Music}
					handleUploadMusic={setMusic}
					handleSetStart={(nb: number) => {
						if (nb < 0) {
							setStart(0);
						} else {
							setStart(nb);
						}
					}}
					handleSetDuration={setDuration}
					start={start}
					duration={duration}
				/>
			</Route>

			{(!file || !music) && <Redirect to={'/create/1'} />}
			<Route path={'/create/4'}>
				<Details
					fileSrc={(file as Asset)?.url}
					music={music as Music}
					handleSubmit={handleSubmit}
				/>
			</Route>

			{(!file || !music || !emojam) && <Redirect to={'/create/1'} />}
			<Route path={'/create/5'}>
				<End emojam={emojam} handleClear={handleClear} />
			</Route>
			<Route path={'/create/links'}>
				<EndLinks
					emojam={emojam}
					handleClear={handleClear}
					onClose={() => {
						history.push('/create/5');
					}}
				/>
			</Route>
			<Redirect to={'/create/1'} />
		</Switch>
	);
};
