import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

// #region private methods

const _getBaseRequestConfig = (
	url: string,
	options: null | AxiosRequestConfig = {}
) => ({ withCredentials: true, ...options, url });

const _onSuccess = (response: AxiosResponse) => response.data;
const _onError = (err: AxiosError) => {
	throw err;
};

// #endregion
// #region public methods

export const get = <T extends any>(
	url: string,
	options?: null | AxiosRequestConfig,
	onError?: (err: any) => any
): Promise<T> =>
	axios({
		..._getBaseRequestConfig(url, options),
		method: 'GET',
	})
		.then(_onSuccess)
		.catch(onError || _onError);

export const patch = <T extends any>(
	url: string,
	options?: null | AxiosRequestConfig,
	onError?: (err: any) => any
): Promise<T> =>
	axios({
		..._getBaseRequestConfig(url, options),
		method: 'PATCH',
	})
		.then(_onSuccess)
		.catch(onError || _onError);

export const post = <T extends any>(
	url: string,
	options?: null | AxiosRequestConfig,
	onError?: (err: any) => any
): Promise<T> =>
	axios({
		..._getBaseRequestConfig(url, options),
		method: 'POST',
	})
		.then(_onSuccess)
		.catch(onError || _onError);

export const put = <T extends any>(
	url: string,
	options?: null | AxiosRequestConfig,
	onError?: (err: any) => any
): Promise<T> =>
	axios({
		..._getBaseRequestConfig(url, options),
		method: 'PUT',
	})
		.then(_onSuccess)
		.catch(onError || _onError);

export const del = <T extends any>(
	url: string,
	options?: null | AxiosRequestConfig,
	onError?: (err: any) => any
): Promise<T> =>
	axios({
		..._getBaseRequestConfig(url, options),
		method: 'DELETE',
	})
		.then(_onSuccess)
		.catch(onError || _onError);

export default axios;

// #endregion
