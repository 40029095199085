import thunk from 'redux-thunk';
import reducers from '../client/reducers';
import { createStore, applyMiddleware, AnyAction } from 'redux';

// #region definitions

const middlewares = [thunk];

const composeEnhancers = true // is dev
	? require('redux-devtools-extension').composeWithDevTools({})
	: require('redux').compose;

const store = createStore(
	reducers,
	composeEnhancers(applyMiddleware(...middlewares))
);

// #endregion
// #region public methods

export const dispatch = (action: AnyAction): AnyAction =>
	store.dispatch(action);

export default store;

// #endregion
