const config = {
	apiKey: 'AIzaSyB9FSa9uglPVBa9lFIMSuqrB85Wk5THMWw',
	authDomain: 'emojam-280614.firebaseapp.com',
	databaseURL: 'https://emojam-280614.firebaseio.com',
	projectId: 'emojam-280614',
	storageBucket: 'emojam-280614.appspot.com',
	messagingSenderId: '361895129934',
	appId: '1:361895129934:web:24fba4ef71dd26c56a641f',
	measurementId: 'G-ZC3T7G2P5C',
};

export default config;
