import React, { FC } from 'react';
import { Logo } from '../../Logo';
import { Link } from 'react-router-dom';
import './UnAuthed.styles.scss';

export const UnAuthedLayout: FC = ({ children }) => {
	return (
		<>
			<div className="container">
				<div id="UnAuthedBody" className="flex wrap">
					<div className="title">
						<div className="logo">
							<Link to="/login">
								<Logo />
							</Link>
						</div>
						<div>
							create audio GIFs
							<br />
							for any
							<br />
							moment.
						</div>
					</div>
					<div className="content">{children}</div>
				</div>
			</div>
		</>
	);
};
