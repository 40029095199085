import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as firebase from 'firebase/app';
import { Input } from './Shared/Input';
import { Button } from './Shared/Button';
import { post } from '../../modules/axios';
import { Menu, Item } from './Shared/Menu';
import { useDispatch } from 'react-redux';
import { pushMessage } from '../actions/messages';
import googleButton from './Settings/googleButton1.png';
import { Divider } from './Divider';
import { GoogleAuthButton } from './AuthButtons/Google';

export const SignUp: FC = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [username, setUsername] = useState('');
	const [loading, setLoading] = useState(false);

	const history = useHistory();
	const dispatch = useDispatch();

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setLoading(true);
		post('/api/users/create', {
			data: {
				email,
				password,
				username,
			},
		})
			.then(_user => {
				firebase
					.auth()
					.signInWithEmailAndPassword(email, password)
					.then(() => {
						history.push('/');
					});
			})
			.catch(e => {
				const message = e?.response?.data?.message || e.message;
				dispatch(pushMessage(message, 'error'));

				if (
					message ===
					'The email address is already in use by another account.'
				) {
					history.push('/login');
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleSignupWithGoogle = () => {
		setLoading(true);
		const provider = new firebase.auth.GoogleAuthProvider();
		firebase
			.auth()
			.signInWithPopup(provider)
			.catch(err => {
				dispatch(pushMessage(err.message, 'error'));
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<>
			<Menu>
				<Item to={'/login'}>log in</Item>
				<Item to={'/signup'}>sign up</Item>
			</Menu>
			<div className="unAuthedForm">
				<form onSubmit={handleSubmit}>
					<div className="AuthButtonsGroup">
						<div
							onClick={handleSignupWithGoogle}
							className="pointer">
							<GoogleAuthButton />
						</div>
					</div>
					<Divider label={'or'} />

					<div className="UnAuthedInputs">
						<Input
							autoComplete={'false'}
							disabled={loading}
							className="inline-block"
							type={'email'}
							placeholder={'email'}
							value={email}
							onChange={e => setEmail(e.currentTarget.value)}
						/>
						<Input
							autoComplete={'false'}
							disabled={loading}
							className="inline-block"
							type={'text'}
							placeholder={'username'}
							value={username}
							onChange={e => setUsername(e.currentTarget.value)}
						/>
						<Input
							autoComplete={'false'}
							disabled={loading}
							className="inline-block"
							type={'password'}
							placeholder={'password'}
							value={password}
							onChange={e => setPassword(e.currentTarget.value)}
						/>
					</div>
					<div
						style={{
							textAlign: 'left',
							color: 'white',
							fontSize: 9,
							marginTop: 20,
						}}>
						{' '}
						By creating an account, you agree to emojam’s{' '}
						<a
							rel="noopener noreferrer"
							target="_blank"
							href="https://www.emojam.com/terms"
							style={{ color: 'white' }}>
							terms of use
						</a>
						.
					</div>
					<Button type="submit" disabled={loading}>
						sign up
					</Button>
				</form>
			</div>
		</>
	);
};
