import React, { FC, useState } from 'react';
import { MainLayout, MainLeftLayout, MainRightLayout } from '../Layouts/Main';
import { Preview } from '../Preview';
import { Stepper } from '../Shared/Stepper';
import { SearchInput } from '../Shared/Search';
import { AudioPlayer } from '../AudioPlayer';
import { Button } from '../Shared/Button';
import { Music, Asset } from '.';
import { auth } from 'firebase';
import { post } from '../../../modules/axios';
import { useHistory } from 'react-router-dom';
import { ReadMore } from '../Shared/ReadMore';

type Props = {
	fileSrc: string;
	music: Music;
	handleUploadMusic: (music: Music | null) => void;
	handleSetStart: (start: number) => void;
	handleSetDuration: (duration: number) => void;
	start: number;
	duration: number;
};

const getUrl = (music: Music) => {
	return typeof music.url === 'string'
		? music.url
		: URL.createObjectURL(music.url);
};

export const TrimMusic: FC<Props> = ({
	fileSrc,
	music,
	handleUploadMusic,
	handleSetStart,
	handleSetDuration,
	start,
	duration,
}) => {
	const [playingGif, setPlayingGif] = useState(false);
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	const handleSubmit = async () => {
		if (music) {
			const token = await auth().currentUser?.getIdToken();
			const formData = new FormData();
			formData.append('file', music.url as File);
			formData.append('start', start.toString());
			formData.append('duration', duration.toString());

			setLoading(true);
			return post<Asset>('/api/assets', {
				headers: {
					'Content-Type': 'multipart/form-data',
					authorization: `Bearer ${token}`,
				},
				data: formData,
			})
				.then(asset => {
					history.push('/create/4');
					handleUploadMusic({
						...music,
						url: asset,
					});
				})
				.catch(e => alert(e))
				.finally(() => setLoading(false));
		}
	};

	return (
		<MainLayout>
			<MainLeftLayout>
				<div className="DashboardLeftWrapper">
					<div className="DashboardLeftNumber">3</div>
					<div className="DashboardLeftContent">
						<div className="DashboardLeftTitle">
							<span>
								then trim
								<br />
								your music.
							</span>
						</div>
						<div className="DashboardLeftDescription">
							<ReadMore>
								These 30 seconds of the song are allowed for use
								with emojam studio through Spotify. To use a
								clip from a different part of the song, please
								add your own audio file.
							</ReadMore>
						</div>
					</div>
				</div>
			</MainLeftLayout>
			<MainRightLayout>
				<div className="flex space-between wrap-reverse align-center top-25">
					<div className="CreatePreviewWrapper">
						<Preview src={fileSrc} playing={playingGif} dashed />
					</div>
					<Stepper
						steps={[
							{ value: 1, link: '/create/1' },
							{ value: 2, link: '/create/2' },
							{ value: 3 },
							{ value: 4 },
						]}
						currentIndex={2}
					/>
				</div>
				<div className="SearchWrapper">
					<SearchInput
						isSet={true}
						placeholder={'search on apple music'}
						onClear={() => handleUploadMusic(null)}
						value={
							music.title ||
							(typeof music.url === 'string'
								? music.url
								: (music.url as File).name)
						}
						disabled={true}
					/>
				</div>
				<div className="MusicPlayer">
					<AudioPlayer
						url={getUrl(music)}
						handleSetDuration={handleSetDuration}
						handleSetStart={handleSetStart}
						start={start}
						duration={duration}
						onPause={() => {
							setPlayingGif(false);
						}}
						onPlay={() => {
							setPlayingGif(true);
						}}
					/>
				</div>
				<div className="ButtonsWrapper">
					<Button
						size="ghost"
						style={{ color: 'black' }}
						onClick={() => handleUploadMusic(null)}>
						back
					</Button>
					<Button
						size="normal"
						onClick={handleSubmit}
						disabled={duration > 15 || loading}>
						{loading ? 'uploading' : 'next'}
					</Button>
				</div>
			</MainRightLayout>
		</MainLayout>
	);
};
