import React, { FC, useState } from 'react';
import {
	MainLayout,
	MainLeftLayout,
	MainRightLayout,
} from '../components/Layouts/Main';
import axios, { patch } from '../../modules/axios';
import { auth, analytics } from 'firebase';
import { useParams, useHistory } from 'react-router-dom';
import { useRequest } from '@umijs/hooks';
import { Update } from '../components/Update';
import { EmojamUpdatePayload } from '../../types/api';

const getEmojam = (id: string): Promise<any> => {
	return new Promise(async resolve => {
		const token = await auth().currentUser?.getIdToken();
		axios
			.get<any>(`/api/emojams/${id}`, {
				headers: {
					authorization: `Bearer ${token}`,
				},
			})
			.then(data => {
				return resolve(data);
			});
	});
};

const deleteEmojam = async (id: string) => {
	const token = await auth().currentUser?.getIdToken();

	return axios.delete(`/api/emojams/${id}`, {
		headers: {
			authorization: `Bearer ${token}`,
		},
	});
};

const updateEmojam = async (id: string, data: EmojamUpdatePayload) => {
	const token = await auth().currentUser?.getIdToken();

	return patch(`/api/emojams/${id}`, {
		headers: {
			authorization: `Bearer ${token}`,
		},
		data,
	});
};

export const UpdatePage: FC = () => {
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const { data } = useRequest(() => getEmojam(id));

	const handleDelete = () => {
		deleteEmojam(id).then(() => {
			analytics().logEvent('emojamDelete');
			history.push('/');
		});
	};

	const handleUpdate = (data: EmojamUpdatePayload) => {
		try {
			setLoading(true);
			updateEmojam(id, data).finally(() => {
				setLoading(false);
				analytics().logEvent('emojamUpdate');
				history.push('/');
			});
		} catch (error) {
			
			console.log(error);
		}
	};

	return (
		<MainLayout>
			<MainLeftLayout>
				<div className="DashboardLeftWrapper">
					<div className="DashboardLeftTitle">
						edit
						<br />
						details.
					</div>
				</div>
			</MainLeftLayout>
			<MainRightLayout>
				{data?.data ? (
					<Update
						initialData={data.data}
						handleDelete={handleDelete}
						handleUpdate={handleUpdate}
						loading={loading}
					/>
				) : null}
			</MainRightLayout>
		</MainLayout>
	);
};
