import { Reducer } from 'redux';
import { Action } from '../actions/messages';

export type State = {
	value: string | null;
	show: boolean;
	type: 'error' | 'info' | 'success';
};

const INITIAL_STATE: State = {
	value: null,
	show: false,
	type: 'info',
};

export const reducer: Reducer<State, Action> = (
	state = INITIAL_STATE,
	action: Action
): State => {
	switch (action.type) {
		case 'messages/PUSH':
			return {
				value: action.payload.value,
				type: action.payload.type,
				show: true,
			};
		case 'messages/RESET':
			return INITIAL_STATE;
		default:
			return state;
	}
};
