import React, { FC } from 'react';
import loaderData from './loader.animation.json';
import Lottie from 'react-lottie';

export const Loader: FC = props => (
	<Lottie
		options={{
			loop: true,
			autoplay: true,
			animationData: loaderData,
			rendererSettings: {
				preserveAspectRatio: 'xMidYMid slice',
			},
		}}
		height={80}
		width={80}
		isStopped={false}
		isPaused={false}
	/>
);
