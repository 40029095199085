import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { UnAuthedLayout } from '../components/Layouts/UnAuthed';
import { Login } from '../components/Login';
import { SignUp } from '../components/SignUp';
import { ForgotPassword } from '../components/ForgotPassword';
import { ResetPassword } from '../components/ResetPassword';

export const UnAuthed: FC = () => {
	return (
		<UnAuthedLayout>
			<Switch>
				<Route path="/login">
					<Login />
				</Route>
				<Route path="/signup">
					<SignUp />
				</Route>
				<Route path="/forgot">
					<ForgotPassword />
				</Route>
				<Route path="/reset">
					<ResetPassword />
				</Route>
				<Redirect to="/login" />
			</Switch>
		</UnAuthedLayout>
	);
};
