import React, { FC, createRef, useState } from 'react';
import { Input } from '../Shared/Input';

import './Links.style.scss';
import { Button } from '../Shared/Button';

type Props = {
	value: string;
	title: string;
	subtitle: string;
};

export const CopyLink: FC<Props> = ({ subtitle, title, value }) => {
	const [tooltipValue, setTooltipValue] = useState('Copy to clipboard');

	const ref = createRef<HTMLInputElement>();
	const copyToclipboard = () => {
		if (ref.current) {
			ref.current.focus();
			ref.current.select();
			ref.current.setSelectionRange(0, 999999); /*For mobile devices*/
			document.execCommand('copy');

			setTooltipValue('copied !');
			setTimeout(() => {
				setTooltipValue('Copy to clipboard');
			}, 1500);
		}
	};

	return (
		<div className="flex wrap justify-end align-center">
			<div className="flex  wrap justify-end" style={{ flex: 1 }}>
				<div className="CopyLinkTitle">{title}</div>
				<Input
					value={value}
					readOnly
					style={{ flex: 1, width: '100%' }}
					placeholder="Processing..."
					ref={ref}
				/>
				<div className="CopyLinkSubtitle"> {subtitle}</div>
			</div>
			<Button
				onClick={() => copyToclipboard()}
				onMouseOut={() => {
					setTooltipValue('Copy to clipboard');
				}}
				tooltip={tooltipValue}
				style={{
					marginLeft: 7,
					marginTop: 7,
					padding: '5px 15px',
					background: 'none',
					border: '1px solid black',
					color: 'black',
					borderRadius: 15,
					fontSize: 14,
					fontWeight: 400,
				}}>
				copy
			</Button>
		</div>
	);
};
