import React, { FC } from 'react';
import Modal, { Props as ModalProps } from 'react-modal';
import { Button } from '../Button';
import './Modal.style.scss';

type Props = {
	isOpen: boolean;
	handleClose: () => void;
	handleDelete: () => void;
	text: string;
	title: string;
} & Partial<ModalProps>;
export const CustomModal: FC<Props> = props => {
	return (
		<Modal
			{...props}
			isOpen={props.isOpen}
			onRequestClose={props.handleClose}
			contentLabel="Example Modal">
			<div className="ModalTitle">{props.title}</div>
			<div className="ModalText">{props.text}</div>
			<div className="ButtonsWrapper">
				<Button
					size="normal"
					onClick={props.handleClose}
					weight="light">
					cancel
				</Button>
				<Button
					size="ghost"
					onClick={props.handleDelete}
					weight="light">
					delete
				</Button>
			</div>
		</Modal>
	);
};
