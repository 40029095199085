import React, { FC, useState } from 'react';
import 'firebase/auth';
import { MainLayout, MainLeftLayout, MainRightLayout } from '../Layouts/Main';
import { Stepper } from '../Shared/Stepper';
import '../App/App.styles.scss';
import './Create.style.scss';
import { SearchInput } from '../Shared/Search';
import { get } from '../../../modules/axios';
import { useRequest } from '@umijs/hooks';
import { Music } from '.';
import { Button } from '../Shared/Button';
import { Preview } from '../Preview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { ReadMore } from '../Shared/ReadMore';

type Props = {
	file: string;
	music: Music | null;
	handleUploadMusic: (music: Music | null) => void;
	handleSetStart: (start: number) => void;
	handleSetDuration: (duration: number) => void;
	start: number;
	duration: number;
};

export const UploadMusic: FC<Props> = ({ file, handleUploadMusic }) => {
	const { data, run, loading } = useRequest(searchMusics, {
		debounceInterval: 100,
		manual: true,
	});

	const [search, setSearch] = useState('');
	const history = useHistory();

	return (
		<MainLayout>
			<MainLeftLayout>
				<div className="DashboardLeftWrapper">
					<div className="DashboardLeftNumber">2</div>
					<div className="DashboardLeftContent">
						<div className="DashboardLeftTitle">
							<span>
								then
								<br />
								soundtrack
								<br />
								your GIF.
							</span>
						</div>
					</div>
				</div>
			</MainLeftLayout>
			<MainRightLayout>
				<div className="flex space-between wrap-reverse align-center top-25">
					<div className="CreatePreviewWrapper">
						<Preview src={file} playing={true} dashed />
					</div>
					<Stepper
						steps={[
							{ value: 1, link: '/create/1' },
							{ value: 2 },
							{ value: 3 },
							{ value: 4 },
						]}
						currentIndex={1}
					/>
				</div>
				<div className="SearchWrapper">
					<SearchInput
						isSet={false}
						placeholder={'search music'}
						onChangeValue={(value: string) => {
							setSearch(value);
							run(value);
						}}
						onClear={() => {
							setSearch('');
						}}
						value={search}
					/>
					{search ? (
						data &&
						data?.tracks?.items &&
						data.tracks.items.length > 0 ? (
							<div className="SearchResults">
								{loading ? (
									<div>
										<FontAwesomeIcon
											color={'black'}
											icon={faSpinner}
											spin
										/>
									</div>
								) : (
									data?.tracks?.items?.map(d => (
										<div
											key={d?.id}
											className="SearchResult"
											onClick={() => {
												if (
													d?.name &&
													d?.artists?.[0]?.name &&
													d.preview_url
												) {
													handleUploadMusic({
														title: d?.name,
														artist:
															d?.artists?.[0]
																?.name,
														url: d.preview_url,
														link:
															d.external_urls
																.spotify,
													});
													history.push('/create/3');
												}
											}}>
											<strong>{d?.name}</strong>
											<br />
											{d?.artists?.[0]?.name}
										</div>
									))
								)}
							</div>
						) : loading ? (
							<div className="SearchResults">
								<FontAwesomeIcon
									icon={faSpinner}
									spin
									color={'black'}
								/>
							</div>
						) : (
							<div className="SearchResults">No Results</div>
						)
					) : null}

					<div
						style={{
							marginTop: 8,
							fontSize: 14,
						}}>
						<input
							className="inputfile"
							accept={'audio/*'}
							type="file"
							id="inputfilemusic"
							onChange={e => {
								if (e.target.files?.[0]) {
									handleUploadMusic({
										title: '',
										artist: '',
										url: e.target.files?.[0],
										link: '',
									});
									history.push('/create/3');
								}
							}}
						/>
						or just{' '}
						<label htmlFor="inputfilemusic">
							upload your audio (mp3, m4a)
						</label>
					</div>
				</div>

				<div className="ButtonsWrapper">
					<Button
						size="ghost"
						to={'/create/1'}
						style={{ color: 'black' }}>
						back
					</Button>
					<Button size="normal" disabled={true} to={'/create/3'}>
						next
					</Button>
				</div>
			</MainRightLayout>
		</MainLayout>
	);
};

const searchMusics = (search: string): Promise<SpotifySearch | null> => {
	return new Promise(async resolve => {
		if (search === '') {
			return resolve(null);
		}

		get<SpotifySearch>(`/api/search/music?q=${search}`).then(res => {
			return resolve(res);
		});
	});
};

type SpotifySearch = {
	tracks: {
		href: string;
		items: [
			{
				album: {
					album_type: string;
					artists: [
						{
							external_urls: {
								spotify: string;
							};
							href: string;
							id: string;
							name: string;
							type: string;
							uri: string;
						}
					];
					available_markets: string[];
					external_urls: {
						spotify: string;
					};
					href: string;
					id: string;
					images: Array<{
						height: number;
						url: string;
						width: number;
					}>;
					name: string;
					release_date: string;
					release_date_precision: string;
					total_tracks: number;
					type: string;
					uri: string;
				};
				artists: [
					{
						external_urls: {
							spotify: string;
						};
						href: string;
						id: string;
						name: string;
						type: string;
						uri: string;
					}
				];
				available_markets: string[];
				disc_number: number;
				duration_ms: number;
				explicit: boolean;
				external_ids: {
					isrc: string;
				};
				external_urls: {
					spotify: string;
				};
				href: string;
				id: string;
				is_local: false;
				name: string;
				popularity: number;
				preview_url: string | null;
				track_number: number;
				type: string;
				uri: string;
			}
		];
		limit: number;
		next: string | null;
		offset: number;
		previous: string | null;
		total: number;
	};
};
