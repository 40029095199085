import React, { FC, ButtonHTMLAttributes } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import './style.scss';
import { Tooltip } from '../Tooltip';

type Props = {
	size?: 'small' | 'normal' | 'ghost';
	to?: string;
	loading?: boolean;
	tooltip?: string;
	weight?: 'normal' | 'light';
};

export const Button: FC<ButtonHTMLAttributes<HTMLButtonElement> & Props> = ({
	loading = false,
	size = 'small',
	weight = 'normal',
	to,
	disabled,
	children,
	tooltip,
	...props
}) => {
	const history = useHistory();
	const handleClick = () => {
		if (to) {
			history.push(to);
		}
	};

	if (tooltip) {
		return (
			<div className="tooltip">
				<button
					{...props}
					disabled={loading || disabled}
					onClick={e => {
						to && handleClick();
						props?.onClick?.(e);
					}}
					className={classnames(
						'CustomButton',
						size,
						props.className
					)}>
					<Tooltip value={tooltip} />

					{children}
				</button>
			</div>
		);
	}

	return (
		<button
			{...props}
			disabled={loading || disabled}
			onClick={e => {
				to && handleClick();
				props?.onClick?.(e);
			}}
			className={classnames('CustomButton', size, props.className, {
				WeightLight: weight === 'light',
			})}>
			{children}
		</button>
	);
};
