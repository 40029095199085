import React, { FC } from 'react';
import { MainLayout, MainLeftLayout, MainRightLayout } from '../Layouts/Main';
import { Button } from '../Shared/Button';
import { Link } from 'react-router-dom';

import './Create.style.scss';
import { FetchPreview } from '../FetchPreview';
import { Emojam } from '../../../types/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faPen } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../Icon';
import { ReadMore } from '../Shared/ReadMore';

type Props = {
	emojam: Emojam;
	handleClear: () => void;
};

export const End: FC<Props> = ({ emojam, handleClear }) => {
	return (
		<MainLayout>
			<MainLeftLayout>
				<div className="DashboardLeftWrapper">
					{/* <div className="DashboardLeftNumber">5</div> */}
					<div className="DashboardLeftContent">
						<div
							className="DashboardLeftTitle"
							style={{
								fontSize: 60,
								lineHeight: '68px',
							}}>
							{emojam.visibility === 'public' ? (
								<span>your emojam is live.</span>
							) : (
								<span>your emojam has been created</span>
							)}
						</div>
						<div className="DashboardLeftDescription">
							<ReadMore>
								{emojam.visibility === 'public' ? (
									<span>
										To edit details, click the pen icon. You
										can always edit later from the "my
										emojams" section.
									</span>
								) : (
									<span>
										To make this audio gif shareable, click
										the pencil icon and turn "public" on.
										You can always publish it later from the
										"my emojams" section.
									</span>
								)}
							</ReadMore>
						</div>
					</div>
				</div>
				<Button size="normal" to={'/create'} onClick={handleClear}>
					create another emojam
				</Button>
			</MainLeftLayout>
			<MainRightLayout>
				<div
					style={{
						position: 'relative',
						marginTop: 30,
					}}>
					<div
						style={{
							left: 'calc(50% - 25px)',
							textAlign: 'center',
							top: -27,
							zIndex: 1,
							position: 'absolute',
						}}>
						<Icon />
					</div>
					<FetchPreview emojam={emojam} dashed />
				</div>
				<div
					style={{
						padding: '0 3px',
						width: '500px',
						maxWidth: '100%',
						margin: '0 auto',
						marginTop: 25,
					}}>
					<div
						style={{
							fontSize: 18,
						}}>
						<div>{emojam?.name}</div>
						<div>
							<strong>
								{emojam?.sources?.[0] ||
									emojam?.sources?.[0]?.name}
							</strong>
						</div>
					</div>
					<div className="EndIcons">
						<Link to={`/update/${emojam.id}`} color="black">
							<FontAwesomeIcon icon={faPen} />
						</Link>
						<Link to={`/create/links`} color="black">
							<FontAwesomeIcon icon={faLink} />
						</Link>
					</div>
				</div>
			</MainRightLayout>
		</MainLayout>
	);
};
