import React, { FC, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import * as firebase from 'firebase/app';
import { Input } from '../Shared/Input';
import { Button } from '../Shared/Button';
import { Menu, Item } from '../Shared/Menu';

import './Login.style.scss';
import { useDispatch } from 'react-redux';
import { pushMessage } from '../../actions/messages';
import { getErrorMessage } from '../../../modules/firebase';
import { GoogleAuthButton } from '../AuthButtons/Google';
import { Divider } from '../Divider';

export const Login: FC = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const history = useHistory();
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setLoading(true);

		firebase
			.auth()
			.signInWithEmailAndPassword(email, password)
			.then(() => {
				history.push('/');
			})
			.catch(e => {
				dispatch(pushMessage(getErrorMessage(e), 'error'));
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleSigninWithGoogle = () => {
		setLoading(true);
		const provider = new firebase.auth.GoogleAuthProvider();
		firebase
			.auth()
			.signInWithPopup(provider)
			.catch(e => {
				dispatch(pushMessage(getErrorMessage(e), 'error'));
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<>
			<Menu>
				<Item to={'/login'}>log in</Item>
				<Item to={'/signup'}>sign up</Item>
			</Menu>
			<div className="unAuthedForm">
				<div className="Login">
					<div className="AuthButtonsGroup">
						<div
							onClick={handleSigninWithGoogle}
							className="pointer">
							<GoogleAuthButton />
						</div>
					</div>
					<Divider label={'or'} />

					<form onSubmit={handleSubmit}>
						<div className="UnAuthedInputs">
							<Input
								disabled={loading}
								className="inline-block"
								type={'email'}
								placeholder={'email'}
								value={email}
								onChange={e => setEmail(e.currentTarget.value)}
							/>
							<Input
								disabled={loading}
								className="inline-block"
								type={'password'}
								placeholder={'password'}
								value={password}
								onChange={e =>
									setPassword(e.currentTarget.value)
								}
							/>
							<Link to="/forgot">forgot your password?</Link>
						</div>
						<Button type="submit" disabled={loading}>
							log in
						</Button>
					</form>
				</div>
			</div>
		</>
	);
};
