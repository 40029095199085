import React, { FC, useState } from 'react';
import { useRequest } from '@umijs/hooks';
import axios from '../../../modules/axios';
import { auth } from 'firebase';

import './FetchPreview.style.scss';
import { Loader } from '../Shared/Loader';
import { Preview } from '../Preview';

type Props = {
	emojam: any;
	width?: number;
	dashed?: boolean;
};

const getEmojam = (id: string): Promise<any> => {
	return new Promise(async resolve => {
		const token = await auth().currentUser?.getIdToken();
		axios
			.get<any>(`/api/emojams/${id}`, {
				headers: {
					authorization: `Bearer ${token}`,
				},
			})
			.then(data => {
				return resolve(data);
			});
	});
};

export const FetchPreview: FC<Props> = ({ emojam, width, dashed }) => {
	const [audio, setAudio] = useState(emojam.audios?.[0]);
	const [gif, setGif] = useState(emojam.animated_images?.[0]);
	const [playing, setPlaying] = useState(false);

	const { cancel } = useRequest(() => getEmojam(emojam.id), {
		pollingInterval: 1000,
		pollingWhenHidden: false,
		onSuccess: (data: any) => {
			if (data?.data.audios?.[0]) {
				setAudio(data?.data.audios?.[0]);
			}

			if (data?.data.animated_images?.[0]) {
				setGif(data?.data.animated_images?.[0]);
			}

			if (data?.data.audios?.[0] && data?.data.animated_images?.[0]) {
				cancel();
			}
		},
	});

	if (!audio && !gif) {
		return (
			<div
				className="FetchPreview"
				style={{
					width: width || 500,
					maxWidth: '100%',
				}}>
				<div style={{ paddingTop: '100%', position: 'relative' }}>
					<div className="FetchPreviewContent">
						<div>
							<Loader />
							<br />
							<div>Waiting for audio and gif generation</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	if (!gif) {
		return (
			<div
				className="FetchPreview"
				style={{
					width: width || 500,
					maxWidth: '100%',
				}}>
				<div style={{ paddingTop: '100%', position: 'relative' }}>
					<div className="FetchPreviewContent">
						<div>
							<Loader />
							<br />
							<div>Waiting for gif generation</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	if (!audio) {
		return (
			<div
				className="FetchPreview"
				style={{
					width: width || 500,
					maxWidth: '100%',
				}}>
				{' '}
				<div style={{ paddingTop: '100%', position: 'relative' }}>
					<div className="FetchPreviewContent">
						<div>
							<Loader />
							<br />
							<div>Waiting for audio generation</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div
			style={{
				margin: '0 auto',
				width: width || 500,
				maxWidth: '100%',
			}}>
			<Preview
				emojam={emojam}
				src={gif.url}
				playing={playing}
				dashed={dashed}
				onToggle={() => {
					setPlaying(!playing);
				}}
			/>
		</div>
	);
};
