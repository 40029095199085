import React, { FC } from 'react';

import './Switch.style.scss';

export const Switch: FC<
	React.InputHTMLAttributes<HTMLInputElement>
> = props => {
	return (
		<div className="onoffswitch">
			<input
				type="checkbox"
				name={props.id}
				className="onoffswitch-checkbox"
				tabIndex={0}
				{...props}
			/>
			<label className="onoffswitch-label" htmlFor={props.id}>
				<span className="onoffswitch-inner"></span>
				<span className="onoffswitch-switch"></span>
			</label>
		</div>
	);
};
