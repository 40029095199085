import React, { FC, useRef, useEffect, useState } from 'react';
import classnames from 'classnames';
import './Preview.style.scss';
import { Emojam } from '../../../types/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { pushMessage } from '../../actions/messages';
import { Skeleton } from '../Shared/Skeleton';
import { GifReader } from './NewPreview';
import { SkeletonSquare } from '../Shared/Skeleton/Square';

type Props = {
	emojam?: Emojam;
	src: string;
	playing?: boolean;
	height?: number;
	onToggle?: () => void;
	onRefresh?: (cb: () => void) => void;
	onLoad?: () => void;
	dashed?: boolean;
};

export const Preview: FC<Props> = ({
	emojam,
	src,
	playing,
	onToggle,
	onLoad,
	dashed,
}) => {
	const ref = useRef();
	const containerRef = useRef();
	const audioRef = useRef<HTMLAudioElement>();
	const [playingGif, setPlayingGif] = useState(false);
	const [isLoad, setIsLoad] = useState(false);
	const [onHover, setonHover] = useState(false);
	const [loading, setLoading] = useState(true);

	const dispatch = useDispatch();

	const [audio] = useState<HTMLAudioElement>(
		new Audio(emojam?.audios?.[0]?.url)
	);
	const [canPlay, setCanPlay] = useState(true);

	useEffect(() => {
		if (isLoad && canPlay) {
			setLoading(false);
			onLoad?.();
		}
	}, [isLoad, canPlay]);

	useEffect(() => {
		try {
			if (!canPlay) {
				audio?.addEventListener(
					'canplay',
					() => {
						setCanPlay(true);
					},
					false
				);
			}

			if (playing !== undefined) {
				if (playing === true) {
					(ref?.current as any)?.start?.();
				} else {
					(ref?.current as any)?.stop?.();
				}
			}

			if (onHover === true) {
				if (playing === false) {
					setPlayingGif(true);
					(ref?.current as any)?.start?.();
				}
			} else {
				if (playing === false) {
					setPlayingGif(false);
					(ref?.current as any)?.stop?.();
				}
			}
		} catch (error) {
			dispatch(pushMessage(error.message, 'error'));
		}

		return () => {
			try {
				((audioRef?.current as any) as HTMLAudioElement)?.pause?.();
			} catch (error) {
				dispatch(pushMessage(error.message, 'error'));
			}
		};
	}, [playing, onHover]);

	useEffect(() => {
		if (!playing) {
			audio.pause();
			audio.currentTime = 0;
		}
	}, [playing]);

	useEffect(() => {
		return () => {
			audio.pause();
		};
	}, []);

	const toggleAudio = (
		e:
			| React.MouseEvent<HTMLDivElement, MouseEvent>
			| React.TouchEvent<HTMLDivElement>
	) => {
		e.preventDefault();
		e.stopPropagation();
		if (canPlay) {
			if (audio.paused) {
				audio
					.play()
					.then(() => {
						onToggle?.();
					})
					.catch(e => {
						dispatch(pushMessage(e.message, 'error'));
					});
			} else {
				onToggle?.();
				audio.pause();
			}
		}
	};

	return (
		<>
			<div
				className={classnames('Preview relative', {
					dashed,
				})}
				data-url={src}
				ref={containerRef as any}>
				{emojam ? (
					<div
						className="absolute"
						style={{ padding: '8px 15px 15px 8px', zIndex: 2 }}
						onClick={toggleAudio}
						onTouchEnd={toggleAudio}
						onMouseOver={() => {
							setonHover(true);
						}}
						onMouseLeave={() => {
							setonHover(false);
						}}>
						<FontAwesomeIcon
							icon={playing ? faPause : faPlay}
							cursor={'pointer'}
						/>
					</div>
				) : null}

				<div
					className={classnames('flex justify-center align-center')}
					style={{}}
					onMouseOver={() => {
						setonHover(true);
					}}
					onMouseLeave={() => {
						setonHover(false);
					}}>
					<GifReader
						loading={loading}
						src={src}
						playing={playing || playingGif}
						onLoad={() => setIsLoad(true)}
					/>
				</div>
			</div>
		</>
	);
};
