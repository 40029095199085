import React, { InputHTMLAttributes, forwardRef } from 'react';
import './style.scss';

type Props = {
	label?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const Input = forwardRef<HTMLInputElement, Props>(
	({ className, label, ...props }, ref) => {
		if (label) {
			return (
				<div className="field">
					<input
						{...props}
						className={`${className || ''} CustomInput`}
						name={label}
						autoComplete="off"
						id={label}
						ref={ref}
					/>
					{label ? <label htmlFor={label}>{label}</label> : null}
				</div>
			);
		} else {
			return (
				<input
					{...props}
					ref={ref}
					autoComplete="off"
					className={`${className || ''} CustomInput`}
				/>
			);
		}
	}
);
