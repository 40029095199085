import React, { FC, useEffect, useState } from 'react';
import { SkeletonSquare } from '../Shared/Skeleton/Square';
// @ts-ignore
const gifFrames = require('gif-frames');

type GifReaderProps = {
	src: string;
	playing?: boolean;
	onLoad?: () => void;
	loading?: boolean;
};

export const GifReader: FC<GifReaderProps> = ({
	src,
	playing,
	onLoad,
	loading,
}) => {
	const [firstFrameUrl, setFirstFrameUrl] = useState<string | null>(null);

	useEffect(() => {
		gifFrames({ url: src, frames: 0, outputType: 'canvas' })
			.then(function (frameData: any) {
				setFirstFrameUrl(frameData[0].getImage().toDataURL());
				onLoad?.();
			})
			.catch(console.error.bind(console));
	}, []);

	if (loading) {
		return (
			<div className="GifReader">
				<SkeletonSquare />
			</div>
		);
	}
	return (
		<div
			className="GifReader"
			style={{
				backgroundImage: !playing
					? `url(${firstFrameUrl})`
					: `url(${src})`,
			}}
		/>
	);
};
