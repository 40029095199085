import React, { FC, useEffect, useState } from 'react';
import { faPlus, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useLocation } from 'react-router-dom';
import { post } from '../../modules/axios';
import { auth } from 'firebase';
import Modal from 'react-modal';
import { Button } from '../components/Shared/Button';
import { Loader } from '../components/Shared/Loader';

type Props = {
	status: 'loading' | 'success' | 'error';
};

const Infos: FC<Props> = ({ status }) => {
	if (status === 'loading') {
		return (
			<div>
				<div style={{ fontSize: 18, textAlign: 'center' }}>
					Connecting your{' '}
					<span style={{ fontWeight: 700 }}>Youtube</span> acount to{' '}
					<span style={{ fontWeight: 700 }}>Emojam</span>
				</div>
				<div
					style={{
						textAlign: 'center',
						marginTop: 15,
					}}>
					<Loader />
				</div>
			</div>
		);
	} else if (status === 'error') {
		return (
			<div
				style={{
					fontSize: 18,
					textAlign: 'center',
					color: 'firebrick',
				}}>
				Failed to connect your{' '}
				<span style={{ fontWeight: 700 }}>Youtube</span> acount to{' '}
				<span style={{ fontWeight: 700 }}>Emojam</span>
			</div>
		);
	} else {
		return (
			<div style={{ fontSize: 18, textAlign: 'center' }}>
				Connected your <span style={{ fontWeight: 700 }}>Youtube</span>{' '}
				acount to <span style={{ fontWeight: 700 }}>Emojam</span>
			</div>
		);
	}
};

const callYoutube = async (code: string | null) => {
	const token = await auth().currentUser?.getIdToken();

	return post('/api/v1/users/link/youtube/creator', {
		headers: {
			authorization: `Bearer ${token}`,
		},
		data: {
			code,
		},
	});
};

export const PageLinkAccount = () => {
	const [status, setStatus] = useState<'loading' | 'success' | 'error'>(
		'loading'
	);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const location = useLocation();
	const history = useHistory();

	const linkAccount = () => {
		const params = new URLSearchParams(location.search);
		const code = params.get('code');

		if (!code) {
			setStatus('error');
			return;
		}

		callYoutube(code)
			.then(() => {
				setStatus('success');
				setTimeout(() => {
					history.push('/settings/youtube/link');
				}, 500);
			})
			.catch(_error => {
				setStatus('error');
				setTimeout(() => {
					setModalIsOpen(true);
				}, 500);
			});
	};

	useEffect(() => {
		linkAccount();
	}, []);

	const handleRetry = () => {
		setStatus('loading');
		setModalIsOpen(false);
		linkAccount();
	};

	const handleCancel = () => {
		history.push('/settings/youtube/link');
	};
	return (
		<>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
					padding: '0 25px',
					width: 'calc(100% - 50px)',
				}}>
				<div>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginBottom: 80,
							maxWidth: '100%',
						}}>
						<img
							src="https://emojam.nyc3.cdn.digitaloceanspaces.com/assets/Emojam-Icon-04.svg"
							alt="emojam icon"
							style={{
								width: 88,
							}}
						/>
						<FontAwesomeIcon
							icon={faPlus}
							size={'2x'}
							style={{ margin: '0 45px' }}
						/>
						<img
							src="https://emojam.nyc3.cdn.digitaloceanspaces.com/assets/youtube_social_icon_red.png"
							alt="youtube icon"
							style={{
								width: 88,
							}}
						/>
					</div>
					<Infos status={status} />
				</div>
			</div>
			<ErrorModal
				isOpen={modalIsOpen}
				handleClose={() => setModalIsOpen(false)}
				handleCancel={handleCancel}
				handleRetry={handleRetry}
			/>
		</>
	);
};

const ErrorModal: FC<{
	isOpen: boolean;
	handleCancel: () => void;
	handleClose: () => void;
	handleRetry: () => void;
}> = ({ isOpen, handleCancel, handleRetry }) => (
	<Modal
		isOpen={isOpen}
		contentLabel="Error modal"
		ariaHideApp={false}
		style={{
			content: {
				top: '50%',
				left: '50%',
				right: 'auto',
				bottom: 'auto',
				marginRight: '-50%',
				transform: 'translate(-50%, -50%)',
				borderRadius: 0,
				width: 420,
			},
			overlay: {
				backgroundColor: 'rgba(0,0,0,0.2)',
			},
		}}>
		<div
			style={{
				textAlign: 'center',
				color: 'black',
			}}>
			<div
				style={{
					fontSize: 24,
					fontWeight: 600,
					marginBottom: 30,
					marginTop: 20,
				}}>
				sorry... seems you haven’t set up Channel memberships for your
				YouTube channel
			</div>
			<div
				style={{
					fontSize: 18,
					fontWeight: 400,
				}}>
				learn more about{' '}
				<a
					style={{ color: 'black' }}
					href="https://support.google.com/youtube/answer/7636690"
					target="_blank"
					rel="noopener noreferrer">
					YouTube Channel Membership
				</a>
			</div>
			<div
				style={{
					marginTop: 20,
				}}>
				<Button
					onClick={handleCancel}
					style={{
						marginRight: 20,
					}}>
					cancel
				</Button>
				<Button onClick={handleRetry}>try again</Button>
			</div>
		</div>
	</Modal>
);
