import React, { FC } from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import {
	FirebaseAuthProvider,
	IfFirebaseAuthed,
	IfFirebaseUnAuthed,
	FirebaseAuthConsumer,
} from '@react-firebase/auth';
import App from '../components/App';
import config from '../../configs/firebase';
import { UnAuthed } from './UnAuthed';
import { Settings } from '../components/Settings';
import { Create } from '../components/Create';
import { UpdatePage } from './Update';
import { Loader } from '../components/Shared/Loader';
import { LinksPage } from './Links';
import Messages from '../containers/Messages';
import { PageLinkAccount } from './LinkAccount';
import { ResetPassword } from '../components/ResetPassword';

export const AppPage: FC = () => {
	return (
		<FirebaseAuthProvider {...config} firebase={firebase}>
			<Messages />
			<FirebaseAuthConsumer>
				{u => {
					if (u?.firebase?.auth) {
						return (
							<Router>
								<IfFirebaseUnAuthed>
									{() => {
										return <UnAuthed />;
									}}
								</IfFirebaseUnAuthed>
								<IfFirebaseAuthed>
									{() => {
										return (
											<Switch>
												<Route exact={true} path="/">
													<App />
												</Route>
												<Route path="/create">
													<Create />
												</Route>
												<Route path="/settings">
													<Settings />
												</Route>
												<Route path="/update/:id">
													<UpdatePage />
												</Route>
												<Route path="/links/:id">
													<LinksPage />
												</Route>
												<Route path="/connect/youtube">
													<PageLinkAccount />
												</Route>
												<Route path="/reset">
													<div
														style={{
															width: 300,
															margin: '0 auto',
															marginTop: 200,
														}}>
														<ResetPassword />
													</div>
												</Route>
												<Redirect to="/" />
											</Switch>
										);
									}}
								</IfFirebaseAuthed>
							</Router>
						);
					} else {
						return (
							<IfFirebaseUnAuthed>
								{() => {
									return (
										<div
											style={{
												height: '100vh',
												width: '100%',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
											}}>
											<Loader />
										</div>
									);
								}}
							</IfFirebaseUnAuthed>
						);
					}
				}}
			</FirebaseAuthConsumer>
		</FirebaseAuthProvider>
	);
};
