import React, { FC, FormEvent, useState } from 'react';
import { Input } from '../Shared/Input';
import { Button } from '../Shared/Button';

import './ForgotPassword.style.scss';
import { auth } from 'firebase';
import { useDispatch } from 'react-redux';
import { pushMessage } from '../../actions/messages';

export const ForgotPassword: FC = () => {
	const [email, setEmail] = useState('');
	const [isSent, setIsSent] = useState(false);
	const dispatch = useDispatch();

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (email) {
			auth()
				.sendPasswordResetEmail(email)
				.then(() => setIsSent(true))
				.catch(e => {
					dispatch(pushMessage(e.message));
				});
		}
	};

	const handleClear = () => {
		setEmail('');
		setIsSent(false);
	};

	if (isSent && email) {
		return (
			<div className="ForgotPassword">
				<h2>email sent!</h2>

				<p>
					We sent a message to {email} so you can pick your new
					password.
				</p>
				<p>
					Didn’t get the email / Not your email address?{' '}
					<span
						onClick={handleClear}
						style={{
							color: 'yellow',
							cursor: 'pointer',
						}}>
						Try again.
					</span>
				</p>
			</div>
		);
	}

	return (
		<div className="ForgotPassword">
			<h2>forgot password</h2>
			<p>
				Enter your email address to reset your password.
				<br />
				You may need to check your spam folder.
			</p>

			<form onSubmit={handleSubmit}>
				<div
					style={{
						marginBottom: 30,
					}}>
					<Input
						placeholder="email adress"
						className="inline-block"
						value={email}
						onChange={e => setEmail(e.target.value)}
						type={'email'}
						style={{
							width: '100%',
							fontSize: 18,
						}}
					/>
				</div>
				<div className="flex space-between">
					<Button size="ghost" to={'/login'} type="button">
						back
					</Button>
					<Button type="submit"> submit </Button>
				</div>
			</form>
		</div>
	);
};
