import { createAction } from '.';
import { ThunkDispatch } from 'redux-thunk';
import { ApplicationState } from '../reducers';

export type Action = ReturnType<typeof push> | ReturnType<typeof reset>;

export const push = (
	value: string,
	type: 'info' | 'error' | 'success' = 'info'
) =>
	createAction('messages/PUSH', {
		value: value,
		type,
	});
export const reset = () => createAction('messages/RESET');

export const pushMessage = (
	value: string,
	type: 'info' | 'error' | 'success' = 'info'
) => (dispatch: ThunkDispatch<ApplicationState, any, Action>) => {
	dispatch(push(value, type));
	setTimeout(() => {
		dispatch(reset());
	}, 3000);
};
