import React, { FC } from 'react';

import './Tooltip.style.scss';

type Props = {
	value: string;
};

export const Tooltip: FC<Props> = ({ value }) => {
	return <span className="tooltiptext">{value}</span>;
};
