import React, { FC, useState } from 'react';

import './ReadMore.style.scss';

export const ReadMore: FC = ({ children }) => {
	const [isExtended, setIsExtended] = useState(false);
	return (
		<>
			<span className={'ReadMoreLg'}>{children}</span>
			<span className="ReadMore">
				{isExtended ? (
					<>{children}</>
				) : (
					<span
						onClick={() => setIsExtended(true)}
						className="pointer underline">
						read more
					</span>
				)}
			</span>
		</>
	);
};
