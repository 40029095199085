import React, {FC, useState} from 'react';
import { Button } from '../Shared/Button';
import { auth } from 'firebase';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { pushMessage } from '../../actions/messages';
import { Input } from '../Shared/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export const Delete: FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');

    const user = auth().currentUser as firebase.User;
    const handleDelete = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (user.email){
            setLoading(true);
            const credential = auth.EmailAuthProvider.credential(
                user.email, 
                password
            );

            try {
                await user.reauthenticateWithCredential(credential)
                await user.delete();
                history.push('/');
                dispatch(pushMessage(`Your account is delete`, 'info'));
            } catch (error) {
                dispatch(pushMessage(error.message, 'error'));
            }
            setLoading(false)
        }

    }
    const handleCancel = () => {
        history.push('/settings')
    }

    return (
        <form onSubmit={handleDelete}>
            <div className="DashboardLeftTitle">
                are you sure you want to delete your account?
            </div>
            <div className="DashboardLeftDescription">
                All the audio GIFs you created will disappear permanently. There is no way to retrieve them after you delete your account.
                <Input label={'confirm with your password'} placeholder="password" value={password} onChange={e => setPassword(e.currentTarget.value)} type="password" name="password"/>
            </div>
            <div className="ButtonsWrapper">
                <Button
                    size="normal"
                    onClick={handleCancel}>
                    cancel
                </Button>
                <Button
                    disabled={!loading && !password}
                    style={{
                        color: 'rgba($color: #FFF, $alpha: 0.5);'
                    }}
                    size="ghost"
                    type="submit">
                    {loading ? <>delete <FontAwesomeIcon icon={faSpinner} spin={true}/></> : "delete"}
                </Button>
            </div>
        </form>
    )
}