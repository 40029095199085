import React, { FC, useEffect, useState } from 'react';

import './FileUploader.style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CustomFile } from '../../Create';

import { useDispatch } from 'react-redux';
import { pushMessage } from '../../../actions/messages';

type Props = {
	handleLoadFile: (file: CustomFile | null) => void;
	handleDeleteFile: () => void;
	file: CustomFile | null;
};

export const FileUploader: FC<Props> = ({
	file,
	handleDeleteFile,
	handleLoadFile,
}) => {
	const dispatch = useDispatch();
	const [transition, setTransition] = useState(false);
	const [currentFile, setCurrentFile] = useState<CustomFile | null>(null);
	const [ratio, setRatio] = useState<number | null>(null);
	useEffect(() => {
		if (file) {
			setCurrentFile(file);

			setTimeout(() => {
				setTransition(true);
			}, 1);
		} else {
			setTransition(false);
			setTimeout(() => {
				setCurrentFile(null);
			}, 500);
		}
	}, [file]);

	const onLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
		const target = e.currentTarget;
		setRatio(target.width / target.height);
	};
	return (
		<div className="Uploader">
			{currentFile !== null ? (
				<>
					<FontAwesomeIcon
						className={'UploaderClose'}
						icon={faTimes}
						cursor={'pointer'}
						onClick={handleDeleteFile}
					/>
					<img
						src={currentFile.src}
						alt={currentFile.data.name}
						style={{
							maxHeight: !transition ? 0 : '100%',
							maxWidth: !transition ? 0 : '100%',
							width: !transition ? 0 : '100%',
							transitionProperty:
								'max-width max-height background-color font-size left top transform -webkit-transform color',
							transitionDuration: '0.5s',
							transitionTimingFunction: 'ease-in-out',
						}}
						onLoad={onLoad}
					/>
				</>
			) : (
				<>
					<div style={{ zIndex: 1 }}>
						<input
							className="inputfile"
							accept={'image/gif'}
							type="file"
							id="inputfile"
							onChange={e => {
								const uploadedFile = e.target.files?.[0];
								if (uploadedFile) {
									if (uploadedFile.type !== 'image/gif') {
										dispatch(
											pushMessage('Please, upload a GIF')
										);
										return;
									}

									handleLoadFile({
										data: uploadedFile,
										src: URL.createObjectURL(uploadedFile),
									});
								}
							}}
						/>
						<label htmlFor="inputfile">upload a GIF</label>
					</div>
				</>
			)}
		</div>
	);
};
