import React from 'react';

export const YoutubeAuthButton = () => (
	<svg
		width="211"
		height="50"
		viewBox="0 0 211 50"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0)">
			<path
				d="M190.179 50H20.5357C9.19643 50 0 40.8036 0 29.4643V20.5357C0 9.19643 9.19643 0 20.5357 0H190.179C201.518 0 210.714 9.19643 210.714 20.5357V29.4643C210.714 40.8036 201.518 50 190.179 50Z"
				fill="white"
			/>
			<path
				d="M55.9107 22.4641C56.4286 21.9373 57.0893 21.6694 57.8839 21.6694C58.9554 21.6694 59.7321 22.0266 60.2321 22.7319C60.5089 23.1337 60.6518 23.5266 60.6696 23.9284H59.3304C59.2411 23.6159 59.1339 23.3837 59 23.2319C58.7589 22.9551 58.4018 22.8123 57.9375 22.8123C57.4554 22.8123 57.0804 23.0087 56.8036 23.4016C56.5268 23.7944 56.3929 24.3569 56.3929 25.0712C56.3929 25.7855 56.5357 26.3301 56.8304 26.6873C57.125 27.0444 57.4911 27.223 57.9375 27.223C58.4018 27.223 58.75 27.0712 58.9911 26.7587C59.125 26.5891 59.2321 26.348 59.3214 26.0176H60.6518C60.5357 26.7141 60.2411 27.2855 59.7679 27.723C59.2946 28.1605 58.6875 28.3837 57.9464 28.3837C57.0357 28.3837 56.3125 28.0891 55.7857 27.4909C55.2589 26.8926 55 26.0801 55 25.0444C55.0179 23.9373 55.3125 23.0712 55.9107 22.4641Z"
				fill="#606060"
			/>
			<path
				d="M68.0352 27.6517C67.553 28.1517 66.8477 28.4017 65.928 28.4017C65.0084 28.4017 64.303 28.1517 63.8209 27.6517C63.1691 27.0356 62.8477 26.1517 62.8477 24.9999C62.8477 23.8214 63.1691 22.9374 63.8209 22.3481C64.303 21.8481 65.0084 21.5981 65.928 21.5981C66.8477 21.5981 67.5441 21.8481 68.0352 22.3481C68.6869 22.9374 69.0084 23.8214 69.0084 24.9999C68.9994 26.1517 68.678 27.0356 68.0352 27.6517ZM67.1869 26.6785C67.4994 26.2856 67.6602 25.7321 67.6602 24.9999C67.6602 24.2767 67.5084 23.7231 67.1869 23.3303C66.8744 22.9374 66.4548 22.741 65.9191 22.741C65.3834 22.741 64.9637 22.9374 64.6423 23.3303C64.3209 23.7231 64.1691 24.2767 64.1691 25.0089C64.1691 25.741 64.3298 26.2946 64.6423 26.6874C64.9548 27.0803 65.3834 27.2767 65.9191 27.2767C66.4548 27.2767 66.8834 27.0624 67.1869 26.6785Z"
				fill="#606060"
			/>
			<path
				d="M71.3477 21.7944H72.7584L75.3119 26.2766V21.7944H76.5619V28.223H75.2227L72.6066 23.6605V28.223H71.3566V21.7944H71.3477Z"
				fill="#606060"
			/>
			<path
				d="M83.8214 21.7944V22.9284H81.9018V28.2141H80.5536V22.9284H78.625V21.7944H83.8214Z"
				fill="#606060"
			/>
			<path
				d="M87.1702 28.223H85.8398V21.7944H87.1702V28.223Z"
				fill="#606060"
			/>
			<path
				d="M89.75 21.7944H91.1607L93.7143 26.2766V21.7944H94.9643V28.223H93.625L91.0089 23.6605V28.223H89.7589V21.7944H89.75Z"
				fill="#606060"
			/>
			<path
				d="M97.5539 21.7944H98.9199V25.7409C98.9199 26.1784 98.9735 26.5087 99.0717 26.7051C99.2324 27.0623 99.5896 27.2409 100.134 27.2409C100.679 27.2409 101.027 27.0623 101.188 26.7051C101.295 26.4998 101.348 26.1784 101.348 25.7409V21.7944H102.715V25.7409C102.715 26.4284 102.607 26.9551 102.393 27.3391C102 28.0355 101.241 28.3837 100.134 28.3837C99.0181 28.3837 98.2681 28.0355 97.8664 27.3391C97.6521 26.9551 97.5449 26.4284 97.5449 25.7409V21.7944H97.5539Z"
				fill="#606060"
			/>
			<path
				d="M110.099 22.9373H106.697V24.3034H109.822V25.4194H106.697V27.0712H110.251V28.223H105.385V21.7944H110.099V22.9373Z"
				fill="#606060"
			/>
			<path
				d="M117.331 21.7944L118.179 25.473L118.366 26.4998L118.554 25.4998L119.277 21.7944H120.697L121.465 25.473L121.661 26.4998L121.857 25.5176L122.715 21.7944H124.081L122.277 28.223H120.991L120.215 24.4641L119.991 23.223L119.768 24.4641L118.991 28.223H117.741L115.92 21.7944H117.331Z"
				fill="#606060"
			/>
			<path
				d="M127.446 28.223H126.115V21.7944H127.446V28.223Z"
				fill="#606060"
			/>
			<path
				d="M134.714 21.7944V22.9284H132.794V28.2141H131.446V22.9284H129.518V21.7944H134.714Z"
				fill="#606060"
			/>
			<path
				d="M136.83 28.223V21.7944H138.16V24.2409H140.66V21.7944H141.991V28.223H140.66V25.3569H138.16V28.223H136.83Z"
				fill="#606060"
			/>
			<path
				d="M151.956 21.7944H153.474L151.322 25.8123V28.223H149.983V25.8123L147.76 21.7944H149.34L150.67 24.5891L151.956 21.7944Z"
				fill="#606060"
			/>
			<path
				d="M160.527 27.6517C160.045 28.1517 159.34 28.4017 158.42 28.4017C157.501 28.4017 156.795 28.1517 156.313 27.6517C155.661 27.0356 155.34 26.1517 155.34 24.9999C155.34 23.8214 155.661 22.9374 156.313 22.3481C156.795 21.8481 157.501 21.5981 158.42 21.5981C159.34 21.5981 160.036 21.8481 160.527 22.3481C161.179 22.9374 161.501 23.8214 161.501 24.9999C161.501 26.1517 161.179 27.0356 160.527 27.6517ZM159.688 26.6785C160.001 26.2856 160.161 25.7321 160.161 24.9999C160.161 24.2767 160.009 23.7231 159.688 23.3303C159.376 22.9374 158.956 22.741 158.42 22.741C157.884 22.741 157.465 22.9374 157.143 23.3303C156.822 23.7231 156.67 24.2767 156.67 25.0089C156.67 25.741 156.831 26.2946 157.143 26.6874C157.456 27.0803 157.884 27.2767 158.42 27.2767C158.956 27.2767 159.376 27.0624 159.688 26.6785Z"
				fill="#606060"
			/>
			<path
				d="M163.866 21.7944H165.232V25.7409C165.232 26.1784 165.285 26.5087 165.383 26.7051C165.544 27.0623 165.901 27.2409 166.446 27.2409C166.991 27.2409 167.339 27.0623 167.499 26.7051C167.607 26.4998 167.651 26.1784 167.651 25.7409V21.7944H169.017V25.7409C169.017 26.4284 168.91 26.9551 168.696 27.3391C168.303 28.0355 167.544 28.3837 166.437 28.3837C165.33 28.3837 164.571 28.0355 164.169 27.3391C163.955 26.9551 163.848 26.4284 163.848 25.7409V21.7944H163.866Z"
				fill="#606060"
			/>
			<path
				d="M176.321 21.7944V22.9284H174.402V28.2141H173.054V22.9284H171.125V21.7944H176.321Z"
				fill="#606060"
			/>
			<path
				d="M178.446 21.7944H179.812V25.7409C179.812 26.1784 179.865 26.5087 179.963 26.7051C180.124 27.0623 180.481 27.2409 181.026 27.2409C181.571 27.2409 181.919 27.0623 182.08 26.7051C182.187 26.4998 182.231 26.1784 182.231 25.7409V21.7944H183.597V25.7409C183.597 26.4284 183.49 26.9551 183.276 27.3391C182.883 28.0355 182.124 28.3837 181.017 28.3837C179.91 28.3837 179.151 28.0355 178.749 27.3391C178.535 26.9551 178.428 26.4284 178.428 25.7409V21.7944H178.446Z"
				fill="#606060"
			/>
			<path
				d="M191.028 22.4819C191.224 22.7587 191.322 23.0801 191.322 23.4641C191.322 23.8569 191.224 24.1694 191.028 24.4105C190.92 24.5444 190.751 24.6694 190.537 24.7766C190.867 24.8926 191.108 25.0891 191.278 25.3391C191.447 25.598 191.528 25.9105 191.528 26.2766C191.528 26.6605 191.429 26.9998 191.242 27.3034C191.117 27.4998 190.965 27.6694 190.787 27.8123C190.581 27.973 190.34 28.0801 190.063 28.1337C189.787 28.1873 189.483 28.223 189.153 28.223H186.26V21.7944H189.367C190.144 21.8034 190.697 22.0355 191.028 22.4819ZM187.537 22.9105V24.3301H189.099C189.376 24.3301 189.608 24.2766 189.778 24.1694C189.947 24.0623 190.037 23.8748 190.037 23.6069C190.037 23.3034 189.92 23.1069 189.688 23.0176C189.492 22.9551 189.233 22.9194 188.929 22.9194H187.537V22.9105ZM187.537 25.3926V27.1069H189.099C189.376 27.1069 189.599 27.0712 189.751 26.9909C190.037 26.848 190.17 26.5801 190.17 26.1873C190.17 25.8569 190.037 25.6248 189.76 25.4998C189.608 25.4284 189.394 25.3926 189.117 25.3926H187.537Z"
				fill="#606060"
			/>
			<path
				d="M198.767 22.9373H195.365V24.3034H198.49V25.4194H195.365V27.0712H198.919V28.223H194.053V21.7944H198.767V22.9373Z"
				fill="#606060"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M32.6607 15.8125C34.9643 15.8125 38.0179 15.8482 40.2679 16.0536C42.0625 16.2232 42.5179 16.4911 42.9911 16.9196C44.2143 18.0357 44.4911 21.0446 44.5357 24.0357C44.5446 24.6786 44.5446 25.3304 44.5357 25.9732C44.4911 28.9643 44.2232 31.9732 42.9911 33.0893C42.5179 33.5179 42.0714 33.7857 40.2679 33.9554C38.0179 34.1607 34.9643 34.1964 32.6607 34.1964C31.7054 34.1964 30.7589 34.1964 29.8036 34.1964C27.5 34.1964 24.4464 34.1607 22.1964 33.9554C20.4018 33.7857 19.9464 33.5179 19.4732 33.0893C18.2321 31.9643 17.9732 28.9196 17.9286 25.8929C17.9196 25.3214 17.9196 24.7589 17.9286 24.1875C17.9643 21.1429 18.2232 18.0446 19.4732 16.9196C19.9464 16.4911 20.3929 16.2232 22.1964 16.0536C24.4464 15.8482 27.5 15.8125 29.8036 15.8125C30.7589 15.8125 31.7143 15.8125 32.6607 15.8125Z"
				fill="#E01B22"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M28.6348 21.1787V29.0716L35.3491 25.0001L28.6348 21.1787Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0">
				<rect width="210.714" height="50" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
