import { ActionCreator, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ApplicationState } from '../reducers';

// #region typings

export type Action<T extends string> = {
	type: T;
};

export type ActionWithPayload<T extends string, P> = {
	type: T;
	payload: P;
};

export type AsyncAction = ActionCreator<
	Promise<ThunkAction<void, ApplicationState, void, AnyAction>>
>;

// #endregion
// #region public methods

export function createAction<T extends string>(type: T): Action<T>;

export function createAction<T extends string, P>(
	type: T,
	payload: P
): ActionWithPayload<T, P>;

export function createAction<T extends string, P>(type: T, payload?: P) {
	return payload === void 0 ? { type } : { type, payload };
}

// #endregion
