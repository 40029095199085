import React, { FC } from 'react';
import {
	MainLayout,
	MainLeftLayout,
	MainRightLayout,
} from '../components/Layouts/Main';
import axios from '../../modules/axios';
import { auth } from 'firebase';
import { useParams } from 'react-router-dom';
import { useRequest } from '@umijs/hooks';
import { Links } from '../components/Links';
import { Button } from '../components/Shared/Button';
import { FetchPreview } from '../components/FetchPreview';

const getEmojam = (id: string): Promise<any> => {
	return new Promise(async resolve => {
		const token = await auth().currentUser?.getIdToken();
		axios
			.get<any>(`/api/emojams/${id}`, {
				headers: {
					authorization: `Bearer ${token}`,
				},
			})
			.then(data => {
				return resolve(data);
			});
	});
};

export const LinksPage: FC = () => {
	const { id } = useParams<{ id: string }>();
	const { data } = useRequest(() => getEmojam(id));
	const user = auth().currentUser as firebase.User;

	return (
		<MainLayout>
			<MainLeftLayout>
				<div className="DashboardLeftWrapper">
					<div className="DashboardLeftTitle">
						hello {user?.displayName}.
					</div>
					<div className="DashboardLeftDescription">
						Welcome to emojam studio.
						<br />
						Start creating your own emojams and share them
						everywhere.
					</div>
				</div>
				<Button size="normal" to={'/create'}>
					create
				</Button>
			</MainLeftLayout>
			<MainRightLayout>
				{data?.data ? (
					<>
						<FetchPreview emojam={data.data} width={240} />
						<br />
						<br />

						<Links emojam={data.data} />
					</>
				) : null}
			</MainRightLayout>
		</MainLayout>
	);
};
