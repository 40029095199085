import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { pushMessage } from '../../actions/messages';
import { auth } from 'firebase';
import { Loader } from '../Shared/Loader';
import { Button } from '../Shared/Button';
import { post } from '../../../modules/axios';
import { getBaseUri } from '../../../utils';
import { YoutubeAuthButton } from '../AuthButtons/Youtube';

const generateAuthUrl = () => {
	const accessType = 'offline';
	const scope =
		'https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube.channel-memberships.creator%20profile%20email';
	const responseType = 'code';
	const clientId =
		'361895129934-h3k8ksa6csk0knarqg4v1a94u7vgossk.apps.googleusercontent.com';
	const redirect = encodeURIComponent(`${getBaseUri()}/connect/youtube`);

	return `https://accounts.google.com/o/oauth2/v2/auth?access_type=${accessType}&scope=${scope}&response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirect}`;
};

export const Youtube: FC = () => {
	const user = auth().currentUser as firebase.User;
	const [userInfo, setUserInfo] = useState<any>(null);
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	const handleDisconnect = async () => {
		setLoading(true);

		try {
			const token = await auth().currentUser?.getIdToken();
			await post('/api/v1/users/disconnect', {
				headers: {
					authorization: `Bearer ${token}`,
				},
			});

			user.reload()
				.then(() => {
					user.getIdTokenResult(true)
						.then(res => {
							setUserInfo(res);
						})
						.catch(err => {
							dispatch(pushMessage(err.message, 'error'));
						});
				})
				.catch(err => {
					dispatch(pushMessage(err.message, 'error'));
				})
				.finally(() => {
					setLoading(false);
				});
		} catch (error) {
			dispatch(pushMessage(error.message, 'error'));
		}
	};

	useEffect(() => {
		user.reload()
			.then(() => {
				user.getIdTokenResult(true)
					.then(res => {
						setUserInfo(res);
					})
					.catch(err => {
						dispatch(pushMessage(err.message, 'error'));
					});
			})
			.catch(err => {
				dispatch(pushMessage(err.message, 'error'));
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	if (loading || !userInfo) {
		return (
			<div className="DashboardLeftDescription">
				<Loader />
			</div>
		);
	}

	return (
		<>
			{userInfo?.claims?.youtube?.isLinked ? (
				<>
					<div className="DashboardLeftWrapper">
						<div className="DashboardLeftTitle">
							{userInfo?.claims?.youtube?.displayName
								? `${userInfo?.claims?.youtube?.displayName},`
								: ''}{' '}
							your YouTube account is connected!
						</div>
						<div className="DashboardLeftDescription">
							We’ve partnered with YouTube so you can offer
							exclusive audio GIFs to your channel members.
							<a
								style={{ color: 'black' }}
								href=" https://www.emojam.com/faq/how-to-offer-emojam-audio-gifs-to-your-youtube-channel-members"
								target="_blank"
								rel="noopener noreferrer">
								{' '}
								Learn more.
							</a>
						</div>
						<div>
							<Button
								size="normal"
								to="/create/1"
								style={{ color: 'white' }}>
								create audio GIFs
							</Button>
							<Button
								size="ghost"
								disabled={loading}
								onClick={handleDisconnect}
								style={{
									color: 'black',
								}}>
								{loading ? 'disconning' : 'disconnect'}
							</Button>
						</div>
					</div>
				</>
			) : (
				<div className="DashboardLeftWrapper">
					<div className="DashboardLeftTitle">
						offer exclusive audio GIFs to your YouTube members
					</div>
					<div
						className="DashboardLeftDescription"
						style={{ marginTop: 27, marginBottom: 40 }}>
						We’ve partnered with YouTube so you can offer exclusive
						audio GIFs to reward your most loyal fans and amaze
						them, while earning money.{' '}
						<a
							style={{ color: 'black' }}
							href="https://docs.google.com/presentation/d/1KULebEtwtGaFb-oIBQlX-BQ4Nc341TBMNKHfxcXAPc8/edit?usp=sharing"
							target="_blank"
							rel="noopener noreferrer">
							{' '}
							Learn more.
						</a>
					</div>
					<div>
						<a href={generateAuthUrl()}>
							<YoutubeAuthButton />
						</a>
					</div>
				</div>
			)}
		</>
	);
};
