import React from 'react';

export const GoogleAuthButton = () => (
	<div className="AuthButton">
		<svg
			viewBox="0 0 211 50"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0)">
				<path
					d="M190.179 50H20.5357C9.19643 50 0 40.8036 0 29.4643V20.5357C0 9.19643 9.19643 0 20.5357 0H190.179C201.518 0 210.714 9.19643 210.714 20.5357V29.4643C210.714 40.8036 201.518 50 190.179 50Z"
					fill="white"
				/>
				<path
					d="M45.7137 25.3304C45.7137 24.1161 45.6155 23.2321 45.4012 22.3125H31.5352V27.7946H39.678C39.5173 29.1607 38.6244 31.2054 36.6602 32.5893L36.6334 32.7768L41.0173 36.1786L41.3209 36.2054C44.1066 33.6161 45.7137 29.8304 45.7137 25.3304Z"
					fill="#4285F4"
				/>
				<path
					d="M31.5353 39.7771C35.5264 39.7771 38.8746 38.4646 41.321 36.1967L36.6603 32.5896C35.4103 33.4556 33.7407 34.0628 31.5353 34.0628C27.6246 34.0628 24.3121 31.4824 23.1335 27.9199L22.9639 27.9378L18.4014 31.4646L18.3389 31.6342C20.7674 36.4556 25.7585 39.7771 31.5353 39.7771Z"
					fill="#34A853"
				/>
				<path
					d="M23.1249 27.9199C22.8124 27.0003 22.6338 26.0181 22.6338 25.0003C22.6338 23.9824 22.8124 23.0003 23.107 22.0806L23.0981 21.8842L18.482 18.2949L18.3302 18.3664C17.3302 20.3664 16.7588 22.6164 16.7588 25.0003C16.7588 27.3842 17.3302 29.6253 18.3302 31.6342L23.1249 27.9199Z"
					fill="#FBBC05"
				/>
				<path
					d="M31.5353 15.9379C34.3121 15.9379 36.1782 17.1343 37.2496 18.1343L41.4192 14.0629C38.8567 11.679 35.5264 10.2236 31.5353 10.2236C25.7585 10.2236 20.7674 13.5361 18.3389 18.3665L23.1157 22.0808C24.3121 18.5183 27.6246 15.9379 31.5353 15.9379Z"
					fill="#EB4335"
				/>
				<path
					d="M58.9468 22.4646C59.4647 21.9378 60.1254 21.6699 60.9201 21.6699C61.9915 21.6699 62.7683 22.0271 63.2683 22.7324C63.5451 23.1342 63.6879 23.5271 63.7058 23.9288H62.3665C62.2772 23.6163 62.1701 23.3842 62.0361 23.2324C61.7951 22.9556 61.4379 22.8128 60.9736 22.8128C60.4915 22.8128 60.1165 23.0092 59.8397 23.4021C59.5629 23.7949 59.429 24.3574 59.429 25.0717C59.429 25.786 59.5718 26.3306 59.8665 26.6878C60.1611 27.0449 60.5272 27.2235 60.9736 27.2235C61.4379 27.2235 61.7861 27.0717 62.0272 26.7592C62.1611 26.5896 62.2683 26.3485 62.3576 26.0181H63.6879C63.5718 26.7146 63.2772 27.286 62.804 27.7235C62.3308 28.161 61.7236 28.3842 60.9826 28.3842C60.0718 28.3842 59.3486 28.0896 58.8218 27.4914C58.2951 26.8931 58.0361 26.0806 58.0361 25.0449C58.054 23.9378 58.3486 23.0717 58.9468 22.4646Z"
					fill="#606060"
				/>
				<path
					d="M71.0625 27.6522C70.5804 28.1522 69.875 28.4022 68.9554 28.4022C68.0357 28.4022 67.3393 28.1522 66.8482 27.6522C66.1964 27.0361 65.875 26.1522 65.875 25.0004C65.875 23.8218 66.1964 22.9379 66.8482 22.3486C67.3304 21.8486 68.0357 21.5986 68.9554 21.5986C69.875 21.5986 70.5714 21.8486 71.0625 22.3486C71.7143 22.9379 72.0357 23.8218 72.0357 25.0004C72.0357 26.1522 71.7143 27.0361 71.0625 27.6522ZM70.2232 26.679C70.5357 26.2861 70.6875 25.7326 70.6875 25.0004C70.6875 24.2772 70.5357 23.7236 70.2232 23.3308C69.9107 22.9379 69.4911 22.7415 68.9554 22.7415C68.4196 22.7415 68 22.9379 67.6786 23.3308C67.3571 23.7236 67.2054 24.2772 67.2054 25.0093C67.2054 25.7415 67.3661 26.2951 67.6786 26.6879C67.9911 27.0808 68.4196 27.2772 68.9554 27.2772C69.4911 27.2772 69.9107 27.0629 70.2232 26.679Z"
					fill="#606060"
				/>
				<path
					d="M74.375 21.7949H75.7857L78.3393 26.2771V21.7949H79.5893V28.2235H78.25L75.6339 23.661V28.2235H74.3839V21.7949H74.375Z"
					fill="#606060"
				/>
				<path
					d="M86.8576 21.7949V22.9289H84.9379V28.2146H83.5897V22.9289H81.6611V21.7949H86.8576Z"
					fill="#606060"
				/>
				<path
					d="M90.2053 28.2235H88.875V21.7949H90.2053V28.2235Z"
					fill="#606060"
				/>
				<path
					d="M92.7773 21.7949H94.1881L96.7416 26.2771V21.7949H97.9916V28.2235H96.6523L94.0363 23.661V28.2235H92.7863V21.7949H92.7773Z"
					fill="#606060"
				/>
				<path
					d="M100.59 21.7949H101.956V25.7414C101.956 26.1789 102.009 26.5092 102.117 26.7056C102.277 27.0628 102.634 27.2414 103.179 27.2414C103.724 27.2414 104.072 27.0628 104.233 26.7056C104.34 26.5003 104.393 26.1789 104.393 25.7414V21.7949H105.759V25.7414C105.759 26.4289 105.652 26.9556 105.438 27.3396C105.045 28.036 104.286 28.3842 103.179 28.3842C102.072 28.3842 101.313 28.036 100.92 27.3396C100.706 26.9556 100.599 26.4289 100.599 25.7414V21.7949H100.59Z"
					fill="#606060"
				/>
				<path
					d="M113.125 22.9378H109.724V24.3038H112.849V25.4199H109.724V27.0717H113.277V28.2235H108.411V21.7949H113.125V22.9378Z"
					fill="#606060"
				/>
				<path
					d="M120.366 21.7949L121.214 25.4735L121.402 26.5003L121.589 25.5003L122.312 21.7949H123.732L124.491 25.4735L124.687 26.5003L124.884 25.5181L125.741 21.7949H127.107L125.295 28.2235H124.009L123.232 24.4646L123.009 23.2235L122.786 24.4646L122.009 28.2235H120.759L118.938 21.7949H120.366Z"
					fill="#606060"
				/>
				<path
					d="M130.483 28.2235H129.152V21.7949H130.483V28.2235Z"
					fill="#606060"
				/>
				<path
					d="M137.75 21.7949V22.9289H135.83V28.2146H134.482V22.9289H132.554V21.7949H137.75Z"
					fill="#606060"
				/>
				<path
					d="M139.857 28.2235V21.7949H141.188V24.2414H143.688V21.7949H145.018V28.2235H143.688V25.3574H141.188V28.2235H139.857Z"
					fill="#606060"
				/>
				<path
					d="M155.795 23.8041C155.697 23.3666 155.447 23.0631 155.054 22.8845C154.83 22.7863 154.589 22.7416 154.313 22.7416C153.795 22.7416 153.366 22.9381 153.036 23.3309C152.705 23.7238 152.536 24.3041 152.536 25.0898C152.536 25.8756 152.714 26.4381 153.08 26.7684C153.438 27.0988 153.848 27.2595 154.313 27.2595C154.759 27.2595 155.134 27.1345 155.42 26.8756C155.705 26.6166 155.884 26.2773 155.955 25.8577H154.464V24.7863H157.143V28.2327H156.25L156.116 27.4291C155.857 27.7327 155.625 27.947 155.42 28.072C155.063 28.2863 154.634 28.4023 154.116 28.4023C153.268 28.4023 152.572 28.1077 152.027 27.5184C151.464 26.9291 151.179 26.1166 151.179 25.0898C151.179 24.0541 151.464 23.2148 152.036 22.5898C152.607 21.9648 153.366 21.6523 154.304 21.6523C155.125 21.6523 155.777 21.8577 156.277 22.2773C156.768 22.6881 157.054 23.2059 157.125 23.8309H155.795V23.8041Z"
					fill="#606060"
				/>
				<path
					d="M164.706 27.6522C164.224 28.1522 163.519 28.4022 162.599 28.4022C161.679 28.4022 160.983 28.1522 160.492 27.6522C159.84 27.0361 159.519 26.1522 159.519 25.0004C159.519 23.8218 159.849 22.9379 160.492 22.3486C160.974 21.8486 161.679 21.5986 162.599 21.5986C163.519 21.5986 164.215 21.8486 164.706 22.3486C165.358 22.9379 165.679 23.8218 165.679 25.0004C165.679 26.1522 165.358 27.0361 164.706 27.6522ZM163.867 26.679C164.179 26.2861 164.34 25.7326 164.34 25.0004C164.34 24.2772 164.188 23.7236 163.867 23.3308C163.554 22.9379 163.135 22.7415 162.599 22.7415C162.063 22.7415 161.644 22.9379 161.322 23.3308C161.001 23.7236 160.849 24.2772 160.849 25.0093C160.849 25.7415 161.01 26.2951 161.322 26.6879C161.635 27.0808 162.063 27.2772 162.599 27.2772C163.135 27.2772 163.554 27.0629 163.867 26.679Z"
					fill="#606060"
				/>
				<path
					d="M172.991 27.6522C172.509 28.1522 171.804 28.4022 170.884 28.4022C169.964 28.4022 169.268 28.1522 168.777 27.6522C168.125 27.0361 167.804 26.1522 167.804 25.0004C167.804 23.8218 168.125 22.9379 168.777 22.3486C169.259 21.8486 169.964 21.5986 170.884 21.5986C171.804 21.5986 172.5 21.8486 172.991 22.3486C173.643 22.9379 173.964 23.8218 173.964 25.0004C173.964 26.1522 173.643 27.0361 172.991 27.6522ZM172.152 26.679C172.464 26.2861 172.625 25.7326 172.625 25.0004C172.625 24.2772 172.473 23.7236 172.152 23.3308C171.839 22.9379 171.42 22.7415 170.884 22.7415C170.348 22.7415 169.929 22.9379 169.607 23.3308C169.286 23.7236 169.134 24.2772 169.134 25.0093C169.134 25.7415 169.295 26.2951 169.607 26.6879C169.92 27.0808 170.348 27.2772 170.884 27.2772C171.42 27.2772 171.839 27.0629 172.152 26.679Z"
					fill="#606060"
				/>
				<path
					d="M180.652 23.8041C180.554 23.3666 180.304 23.0631 179.911 22.8845C179.688 22.7863 179.447 22.7416 179.17 22.7416C178.652 22.7416 178.224 22.9381 177.893 23.3309C177.563 23.7238 177.393 24.3041 177.393 25.0898C177.393 25.8756 177.572 26.4381 177.938 26.7684C178.304 27.0988 178.706 27.2595 179.17 27.2595C179.616 27.2595 179.991 27.1345 180.277 26.8756C180.563 26.6166 180.741 26.2773 180.813 25.8577H179.322V24.7863H182V28.2327H181.108L180.974 27.4291C180.715 27.7327 180.483 27.947 180.277 28.072C179.92 28.2863 179.483 28.4023 178.974 28.4023C178.125 28.4023 177.42 28.1077 176.884 27.5184C176.322 26.9291 176.036 26.1166 176.036 25.0898C176.036 24.0541 176.322 23.2148 176.893 22.5898C177.465 21.9648 178.224 21.6523 179.161 21.6523C179.983 21.6523 180.634 21.8577 181.125 22.2773C181.625 22.6881 181.902 23.2059 181.983 23.8309H180.652V23.8041Z"
					fill="#606060"
				/>
				<path
					d="M184.616 21.7949H185.956V27.0628H189.161V28.2146H184.607V21.7949H184.616Z"
					fill="#606060"
				/>
				<path
					d="M196.162 22.9378H192.76V24.3038H195.885V25.4199H192.76V27.0717H196.313V28.2235H191.447V21.7949H196.162V22.9378Z"
					fill="#606060"
				/>
			</g>
			<defs>
				<clipPath id="clip0">
					<rect width="210.714" height="50" fill="white" />
				</clipPath>
			</defs>
		</svg>
	</div>
);
