import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';

import './style.scss';

type MenuItemProps = {
	to: string;
	active?: boolean;
};

export const Menu: FC = ({ children }) => {
	return <div className="Menu">{children}</div>;
};

export const Item: FC<MenuItemProps> = ({ children, to, active }) => {
	const history = useLocation();
	let isActive = history.pathname.startsWith(to);

	if (to === '/' && to !== history.pathname) {
		isActive = false;
	}

	if (active) {
		isActive = true;
	}

	return (
		<div className="MenuItem">
			<Link
				to={to}
				className={classnames({
					active: isActive,
				})}>
				{children}
			</Link>
		</div>
	);
};
