import * as firebase from 'firebase/app';
import config from '../configs/firebase';

firebase.initializeApp(config);

export const getClient = () => firebase;

export const getErrorMessage = (e: firebase.FirebaseError) => {
    switch (e.code) {
        case "auth/wrong-password":
            return 'Password doesn’t match'
        default:
            return e.message;
    }
}
