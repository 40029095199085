import React, { FC, useState, useEffect } from 'react';
import 'firebase/auth';
import { Input } from '../Shared/Input';
import { InputTags } from '../Shared/Input/InputTags';
import { Button } from '../Shared/Button';
import { FetchPreview } from '../FetchPreview';
import { EmojamUpdatePayload, Emojam } from '../../../types/api';

import '../App/App.styles.scss';
import { Switch } from '../Shared/Switch';
import { useDispatch } from 'react-redux';
import { auth } from 'firebase';
import { pushMessage } from '../../actions/messages';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { CustomModal } from '../Shared/Modal';
import { CustomModalYoutube } from '../Shared/Modal/ModalYoutube';

type Props = {
	initialData: Emojam;
	handleDelete: () => void;
	handleUpdate: (data: EmojamUpdatePayload) => void;
	loading: boolean;
};

export const Update: FC<Props> = ({
	initialData,
	loading,
	handleDelete,
	handleUpdate,
}) => {
	const [tags, setTags] = useState<string[]>(
		initialData?.tags?.map((tag: any) => tag.name) || []
	);
	const [title, setTitle] = useState(initialData.name);
	const [source, setSource] = useState(initialData.sources?.[0]?.name);
	const [visibility, setVisibility] = useState<
		'public' | 'private' | 'members'
	>(initialData.visibility);
	const [type, setNftType] = useState<any>(initialData.type);             
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const [userInfo, setUserInfo] = useState<any>(null);
	const dispatch = useDispatch();
	const user = auth().currentUser as firebase.User;
	const history = useHistory();

	useEffect(() => {
		user.getIdTokenResult()
			.then(res => {
				setUserInfo(res);
			})
			.catch(err => {
				dispatch(pushMessage(err.message, 'error'));
			});
	}, []);

	const handleCancel = () => {
		history.push('/');
	};

	return (
		<>
			<div>
				<FetchPreview emojam={initialData} width={240} dashed={true} />
			</div>
			<br />
			<br />
			<br />
			<div>
				<div className="flex align-center justify-end direction-column">
					<>
						<div className="SwitchWrapper">
							<label
								style={{
									letterSpacing: '0.05em',
									fontSize: 10,
									marginRight: 20,
								}}
								htmlFor={'public'}>
								public
							</label>
							<Switch
								id="public"
								disabled={type === 'nft'? true : false}
								checked={
									visibility === 'public' ||
									visibility === 'members'
								}
								onChange={() => {
									setVisibility(
										visibility === 'public' ||
											visibility === 'members'
											? 'private'
											: 'public'
									);
								}}
							/>
						</div>
						<div className="SwitchWrapper">
							<label
								style={{
									letterSpacing: '0.05em',
									fontSize: 10,
									marginRight: 20,
								}}
								htmlFor={'members'}>
								for YouTube members only
							</label>
							<Switch
								id={'members'}
								disabled={type === 'nft'? true : false}
								checked={visibility === 'members'}
								onChange={() => {
									if (
										userInfo?.claims?.youtube?.type ===
										'youtube'
									) {
										if (
											visibility === 'private' ||
											visibility === 'public'
										) {
											setVisibility('members');
										} else {
											setVisibility('public');
										}
									} else {
										setIsOpen(true);
									}
								}}
							/>
						</div>
						<div className="SwitchWrapper">
								<label
									style={{
										letterSpacing: '0.05em',
										fontSize: 10,
										marginRight: 20,
									}}
									htmlFor={'nft'}>
									NFT
								</label>
								<Switch
									id={'nft'}
									checked={type ==='nft'}
									onChange={() => {
										if(type ==='nft' && visibility === 'private'){
											setNftType('emojam')
										}
										else{
										setNftType('nft')
										setVisibility('private');
										}
									}
								}
								/>
							</div>
					</>
				</div>
				<div>
					<Input
						disabled={!!initialData.sponsored_link}
						label={'title'}
						placeholder={'title'}
						value={title}
						onChange={e => setTitle(e.currentTarget.value)}
						required={true}
					/>
				</div>
				<div>
					<Input
						disabled={!!initialData.sponsored_link}
						label={'source title'}
						placeholder={'source title'}
						value={source}
						onChange={e => setSource(e.currentTarget.value)}
						required={true}
					/>
				</div>
				{!initialData.sponsored_link || (
					<div>
						<Input
							label={'source link'}
							value={initialData.sponsored_link}
							disabled={true}
						/>
					</div>
				)}

				<div>
					<InputTags tags={tags} setTags={setTags} />
				</div>

				<div
					style={{
						marginTop: 60,
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'baseline',
					}}>
					<FontAwesomeIcon
						icon={faTrash}
						onClick={() => setModalIsOpen(true)}
						cursor={'pointer'}
					/>

					<div className="flex">
						<Button
							size="ghost"
							onClick={handleCancel}
							style={{ color: 'black', padding: '0 20px' }}
							disabled={loading}>
							cancel
						</Button>
						<Button
							size="normal"
							onClick={() =>
								handleUpdate({
									name: title,
									sources: [source],
									tags,
									type,
									visibility,
								})
							}
							disabled={loading || !source || !title}>
							update
						</Button>
					</div>
				</div>
			</div>
			<CustomModal
				isOpen={modalIsOpen}
				handleClose={() => setModalIsOpen(false)}
				className="Modal"
				overlayClassName="Overlay"
				title="are you sure you want to delete your emojam?"
				text="your emojam will be permanently erased."
				handleDelete={handleDelete}
			/>
			<CustomModalYoutube
				title={'for YouTube members only'}
				text={
					'we’ve partnered with YouTube so you can offer exclusive Emojam audio GIFs to your paying members. Connect your YouTube account to see if you are eligible for this feature.'
				}
				className="Modal"
				overlayClassName="Overlay"
				isOpen={isOpen}
				handleClose={() => setIsOpen(false)}
				handleDelete={() => {
					history.push('/settings/youtube/link');
				}}
			/>
		</>
	);
};
