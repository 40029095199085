import React, {FC, FormEvent, useState} from 'react';
import { Input } from '../Shared/Input';
import { Button } from '../Shared/Button';

import '../ForgotPassword/ForgotPassword.style.scss';
import { auth } from 'firebase';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { pushMessage } from '../../actions/messages';

import './ResetPassword.style.scss';

export const ResetPassword: FC = () => {
    const [password, setPassword] = useState('');
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const params = new URLSearchParams(location.search); 
    const oobCode = params.get('oobCode');

    if (!oobCode) {
        history.push('/')
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (password && oobCode) {
            auth().confirmPasswordReset(oobCode, password)
                .then(() => history.push('/login'))
                .catch((e) => {
                    dispatch(pushMessage(e.message))
                })
        }
    }

    return (
        <div className="ForgotPassword">
                <h2>reset password</h2>
                <p>
                    Enter your new password
                </p>
              
                <form onSubmit={handleSubmit}>
                <div style={{
                    marginBottom: 30
                }}>
                <Input
                    placeholder="new password"
                    name="new_password"
                    className="inline-block"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type={'password'}/>
                </div>
                <div className="flex space-between">
                    <Button type="submit"> reset </Button>
                </div>
            </form>
        </div>
    )
}
