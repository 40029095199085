import React, { FC, InputHTMLAttributes, ChangeEvent } from 'react';
import { Input } from '../Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';

import './Search.style.scss';

type Props = {
	onClear: () => void;
	onChangeValue?: (value: string) => void;
	value: string;
	isSet?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export const SearchInput: FC<Props> = ({
	onClear,
	onChangeValue,
	value,
	isSet,
	...props
}) => {
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		onChangeValue?.(e.currentTarget.value);
	};

	return (
		<div className="Search">
			<div
				className={'SearchIcon'}
				style={{
					display: isSet ? 'none' : 'block',
				}}>
				<FontAwesomeIcon icon={faSearch} color={'black'} />
			</div>
			<Input
				type="text"
				className={classnames('SearchInput', {
					isSet,
				})}
				onChange={handleChange}
				value={value}
				{...props}
			/>

			<div
				className="ClearIcon"
				style={{
					display: !isSet ? 'none' : 'block',
				}}>
				<FontAwesomeIcon
					icon={faTimes}
					cursor={'pointer'}
					onClick={onClear}
					color="black"
				/>
			</div>
		</div>
	);
};
