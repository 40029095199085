import React, { FC } from 'react';

type DividerProps = {
	label?: string;
};

export const Divider: FC<DividerProps> = ({ label }) => {
	if (!label) {
		return (
			<div
				style={{
					backgroundColor: 'white',
					height: 1,
					width: '100%',
				}}
			/>
		);
	} else {
		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
				}}>
				<div
					style={{
						backgroundColor: 'white',
						height: 1,
						width: '100%',
					}}
				/>
				<div
					style={{
						fontSize: 14,
						opacity: '0.5',
						margin: '0 15px',
					}}>
					{label}
				</div>
				<div
					style={{
						backgroundColor: 'white',
						height: 1,
						width: '100%',
					}}
				/>
			</div>
		);
	}
};
