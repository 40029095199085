import './client/styles/global.scss';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { AppPage } from './client/pages/App';
import store from './modules/redux-store';

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<Route path="/" component={AppPage} />
		</BrowserRouter>
	</Provider>,
	document.getElementById('root') as HTMLElement
);
