import React, { FC } from 'react';
import { Menu, Item } from '../../Shared/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '../../Icon';

export const MainLayout: FC = ({ children }) => (
	<div className={'Dashboard'}>{children}</div>
);

export const MainLeftLayout: FC = ({ children }) => (
	<div className={'DashboardLeft'}>
		<div className="container">
			<Menu>
				<Item to="/" active>
					<div style={{ height: 50, width: 50 }}>
						<Icon />
					</div>
				</Item>
				<Item to="/">my emojams</Item>
				<Item to="/create">create</Item>
				<Item to="/settings">
					<FontAwesomeIcon icon={faCog} />
				</Item>
			</Menu>

			{children}
		</div>
	</div>
);

export const MainRightLayout: FC = ({ children }) => (
	<div className="DashboardRight" id="DashboardRight">
		<div className="container">{children}</div>
	</div>
);
