import React, {FC, useState} from 'react';
import { Input } from '../Shared/Input';
import { Button } from '../Shared/Button';
import { useDispatch } from 'react-redux';
import { pushMessage } from '../../actions/messages';
import { auth } from 'firebase';

export const Password: FC = () => {
    const user = auth().currentUser as firebase.User;
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const clear = () => {
        setNewPassword('');
        setConfirmNewPassword('');
        setCurrentPassword('');
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (currentPassword && newPassword && !loading && user.email) { 
            if (newPassword !== confirmNewPassword) {
                return dispatch(pushMessage('passwords not match', 'error'))
            }

            const credential = auth.EmailAuthProvider.credential(
                user.email, 
                currentPassword
            );
            setLoading(true)

            try {
                await user.reauthenticateWithCredential(credential)
            } catch (error) {
                setLoading(false)
                clear()
                return dispatch(pushMessage(error.message, 'error'));
            }

            user.updatePassword(newPassword)
                .then(() => {
                    dispatch(pushMessage('password updated', 'success'));
                    clear()
                })
                .catch(e => dispatch(pushMessage(e.message, 'error')))
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="DashboardLeftDescription">
            <Input label={'current password'} placeholder="current password" value={currentPassword} onChange={e => setCurrentPassword(e.currentTarget.value)} type="password" name="password"/>
            <Input label={'new password'} placeholder="new password" value={newPassword} onChange={e => setNewPassword(e.currentTarget.value)} type="password" name="new_password"/>
            <Input label={'confirm new password'} placeholder="confirm new password" value={confirmNewPassword} onChange={e => setConfirmNewPassword(e.currentTarget.value)}type="password" name="new_password_confirm"/>
            </div>
            <div>
            <Button
                loading={loading}
                disabled={loading || (!newPassword || !confirmNewPassword || !confirmNewPassword)}
                size="normal"
                type="submit">
                save
            </Button>
            </div>
        </form>
    )
}