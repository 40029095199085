export const getBaseUri = () => {
	const host = window.location.host;
	switch (host) {
		case 'studio.emojam.com':
			return 'https://studio.emojam.com';
		case 'preprod.emojam.com':
			return 'https://preprod.emojam.com';
		case 'localhost':
			return 'http://localhost';
		default:
			return 'https://studio.emojam.com';
	}
};
