import React, { FC } from 'react';
import { Emojam } from '../../../types/api';
import { CopyLink } from './CopyLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { getBaseUri } from '../../../utils';

type Props = {
	emojam: Emojam;
	onClose?: () => void;
};

export const Links: FC<Props> = ({ emojam, onClose }) => {
	return (
		<div>
			{onClose && (
				<div
					style={{
						marginBottom: 50,
						textAlign: 'right',
					}}>
					<FontAwesomeIcon
						icon={faTimes}
						onClick={onClose}
						cursor={'pointer'}
					/>
				</div>
			)}

			<>
				<CopyLink
					value={`${getBaseUri()}/preview/${emojam.id}`}
					title={'link'}
					subtitle={'a URL to easily share this audio GIF everywhere'}
				/>
				<br />
				<CopyLink
					value={emojam?.videos?.[0]?.url || ''}
					title={'mp4'}
					subtitle={'video file'}
				/>
				<br />
				<CopyLink
					value={emojam?.animated_images?.[0]?.url || ''}
					title={'GIF'}
					subtitle={'small file size'}
				/>
				<br />
				<CopyLink
					value={emojam?.audios?.[0]?.url || ''}
					title={'audio'}
					subtitle={'audio file'}
				/>
			</>
		</div>
	);
};
