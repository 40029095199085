import './App.styles.scss';
import React, { FC, useState, useEffect } from 'react';
import 'firebase/auth';
import { Button } from '../Shared/Button';
import { Preview } from '../Preview';
import { get } from '../../../modules/axios';
import { MainLayout, MainLeftLayout, MainRightLayout } from '../Layouts/Main';
import { Loader } from '../Shared/Loader';
import { auth } from 'firebase';
import { Link } from 'react-router-dom';
import { NoContent } from '../Shared/NoContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faLink } from '@fortawesome/free-solid-svg-icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Emojam, EmojamsResponse } from '../../../types/api';
import { useDispatch } from 'react-redux';
import { pushMessage } from '../../actions/messages';
import classnames from 'classnames';

const LIMIT = 16;

const getEmojams = (
	limit: number,
	offset: number
): Promise<EmojamsResponse> => {
	return new Promise(async resolve => {
		const token = await auth().currentUser?.getIdToken();
		get<EmojamsResponse>(
			`/api/emojams/me?limit=${limit}&offset=${offset}`,
			{
				headers: {
					authorization: `Bearer ${token}`,
				},
			}
		).then(data => {
			return resolve(data);
		});
	});
};

export const Ceil: FC<{
	emojam: Emojam;
	playingId: string | null;
	setPlayingId: (id: string | null) => void;
}> = ({ emojam, playingId, setPlayingId }) => {
	return (
		<div className="PreviewWrapper" tabIndex={1}>
			<>
				<div>
					<Preview
						emojam={emojam}
						src={emojam?.animated_images?.[0]?.url}
						playing={playingId === emojam.id}
						onToggle={() => {
							if (playingId === emojam.id) {
								setPlayingId(null);
							} else {
								setPlayingId(emojam.id);
							}
						}}
						onRefresh={(cb: () => void) => {
							window.addEventListener('resize', cb);
						}}
					/>

					<div className="PreviewName">
						<div className="PreviewNameTitle">
							<strong>{emojam.name}</strong>
							<br />

							{emojam?.sources?.[0]?.name}
						</div>
						<div className="PreviewNameIcons">
							<Link
								to={`/update/${emojam.id}`}
								className="PreviewUpdateButton">
								<FontAwesomeIcon icon={faPen} />
							</Link>
							<Link
								style={{ marginLeft: 30 }}
								to={`/links/${emojam.id}`}
								className="PreviewUpdateButton">
								<FontAwesomeIcon icon={faLink} />
							</Link>
						</div>
					</div>
				</div>
			</>
		</div>
	);
};

export const Grid: FC = () => {
	const [emojams, setEmojams] = React.useState<Array<Emojam | null>>([]);
	const [loaded, setIsLoaded] = React.useState(false);
	const [playingId, setPlayingId] = useState<string | null>(null);
	const [offset, setOffset] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const dispatch = useDispatch();
	const fetchEmojams = (offset: number) => {
		getEmojams(LIMIT, offset)
			.then(res => {
				setHasMore(typeof res.pagination.next === 'string');
				setOffset(offset + LIMIT);
				setEmojams([...emojams, ...res.data]);
				setIsLoaded(true);
			})
			.catch(err => {
				dispatch(pushMessage(err.message, 'error'));
			});
	};

	useEffect(() => {
		fetchEmojams(offset);
	}, []);

	return (
		<InfiniteScroll
			dataLength={emojams.length}
			next={() => fetchEmojams(offset)}
			hasMore={hasMore}
			loader={<Loader />}>
			<div
				className={classnames({
					DashboardRightGrid: true,
					withoutGrid: emojams.length === 0,
					LightGrid: emojams.length < 3,
				})}>
				{loaded ? (
					emojams.length === 0 ? (
						<div
							className="flex justify-center align-center"
							style={{
								width: '100%',
								height: 'calc(100vh - 245px)',
							}}>
							<NoContent />
						</div>
					) : (
						[...emojams].map((emojam, index) =>
							emojam ? (
								<Ceil
									emojam={emojam}
									playingId={playingId}
									setPlayingId={setPlayingId}
									key={`PreviewWrapper-${emojam.id}-${index}`}
								/>
							) : (
								<div
									className="PreviewWrapper"
									key={`PreviewWrapper-Null-${index}`}
								/>
							)
						)
					)
				) : (
					''
				)}
			</div>
		</InfiniteScroll>
	);
};

const App: FC = () => {
	const user = auth().currentUser as firebase.User;
	return (
		<MainLayout>
			<MainLeftLayout>
				<div className="DashboardLeftWrapper">
					<div className="DashboardLeftContent">
						<div className="DashboardLeftTitle">
							<span>hello {user?.displayName}.</span>
						</div>
						<div className="DashboardLeftDescription">
							Welcome to emojam studio.
							<br />
							Start creating your own emojams and share them
							everywhere.
						</div>
					</div>
				</div>
				<Button size="normal" to={'/create'}>
					create
				</Button>
				<div className="MainPageBackground">
					<img src="https://emojam.nyc3.digitaloceanspaces.com/assets/emojamStudioBackground.png" />
				</div>
			</MainLeftLayout>
			<MainRightLayout>
				<Grid />
			</MainRightLayout>
		</MainLayout>
	);
};

export default App;
