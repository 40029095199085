import React, { FC } from 'react';
import Modal, { Props as ModalProps } from 'react-modal';
import { Button } from '../Button';
import './Modal.style.scss';

type Props = {
	isOpen: boolean;
	handleClose: () => void;
	handleDelete: () => void;
	text: string;
	title: string;
} & Partial<ModalProps>;
export const CustomModalYoutube: FC<Props> = props => {
	return (
		<Modal
			{...props}
			isOpen={props.isOpen}
			onRequestClose={props.handleClose}
			contentLabel="Example Modal">
			<div className="ModalLogo">
				<img
					src="https://emojam.nyc3.cdn.digitaloceanspaces.com/assets/youtube_social_icon_red.png"
					alt="youtube logo"
				/>
			</div>
			<br />
			<div className="ModalTitle">{props.title}</div>
			<br />
			<div className="ModalText">{props.text}</div>
			<div className="ButtonsWrapper">
				<Button size="ghost" onClick={props.handleClose} weight="light">
					cancel
				</Button>
				<Button
					size="normal"
					onClick={props.handleDelete}
					weight="light">
					connect
				</Button>
			</div>
		</Modal>
	);
};
