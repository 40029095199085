import React from 'react';

export const CloseSvg = () => (
	<svg
		width="6"
		height="6"
		viewBox="0 0 6 6"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M0.0950673 0.553362L5.27534 5.72653C5.40099 5.85201 5.60346 5.85074 5.72756 5.72369C5.85166 5.59664 5.8504 5.39193 5.72475 5.26645L0.544473 0.0932836C0.418822 -0.0321952 0.216358 -0.0309237 0.0922585 0.0961236C-0.0318414 0.223171 -0.0305839 0.427884 0.0950673 0.553362Z"
			fill="black"
		/>
		<path
			d="M0.553362 5.72476L5.72653 0.544483C5.85201 0.418832 5.85074 0.216369 5.72369 0.0922689C5.59664 -0.0318308 5.39193 -0.0305738 5.26645 0.0950775L0.0932836 5.27535C-0.0321952 5.401 -0.0309237 5.60347 0.0961236 5.72757C0.223171 5.85167 0.427884 5.85041 0.553362 5.72476Z"
			fill="black"
		/>
	</svg>
);
