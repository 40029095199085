import React, { FC } from 'react';
import { MainLayout, MainLeftLayout, MainRightLayout } from '../Layouts/Main';
import { Button } from '../Shared/Button';
import { Emojam } from '../../../types/api';
import { Links } from '../Links';
import './Create.style.scss';
import { ReadMore } from '../Shared/ReadMore';

type Props = {
	emojam: Emojam;
	handleClear: () => void;
	onClose: () => void;
};

export const EndLinks: FC<Props> = ({ emojam, handleClear, onClose }) => {
	return (
		<MainLayout>
			<MainLeftLayout>
				<div className="DashboardLeftWrapper">
					<div className="DashboardLeftContent">
						<div
							className="DashboardLeftTitle"
							style={{
								fontSize: 60,
								lineHeight: '68px',
							}}>
							{emojam.visibility === 'public' ? (
								<span>your emojam is live.</span>
							) : (
								<span>your emojam has been created</span>
							)}
						</div>
						<div className="DashboardLeftDescription">
							<ReadMore>
								{emojam.visibility === 'public' ? (
									<span>
										To edit details, click the pen icon. You
										can always edit later from the "my
										emojams" section.
									</span>
								) : (
									<span>
										To make this audio GIF shareable, click
										the pencil icon and turn "public" on.
										You can always publish it later from the
										"my emojams" section.
									</span>
								)}
							</ReadMore>
						</div>
					</div>
				</div>
				<Button size="normal" to={'/create'} onClick={handleClear}>
					create another emojam
				</Button>
			</MainLeftLayout>
			<MainRightLayout>
				<Links emojam={emojam} onClose={onClose} />
			</MainRightLayout>
		</MainLayout>
	);
};
