import React, { FC, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import './InputNumber.style.scss';

const getTime = (value: number) => {
	const v = value.toFixed(3);
	const mind = value % (60 * 60);
	const minutes = Math.floor(mind / 60);

	const secd = mind % 60;
	const seconds = Math.floor(secd);

	let ms = v.toString().split('.')?.[1];
	return {
		m: minutes,
		s: seconds,
		ms: parseInt(ms.toString().substring(0, 3), 10),
	};
};

export const InputNumber: FC<{
	value: number;
	onChange: (value: number) => void;
}> = ({ value, onChange }) => {
	const time = getTime(value);

	const [focus, setFocus] = useState<'ms' | 's' | 'm' | null>(null);
	const [ms, setMs] = useState<number | null>(time.ms);
	const [s, setS] = useState<number | null>(time.s);
	const [m, setM] = useState<number | null>(time.m);

	const msRef = useRef<any | null>(null);
	const sRef = useRef<any | null>(null);
	const mRef = useRef<any | null>(null);

	const onSubmit = () => {
		onChange(
			parseFloat(
				`${(m || 0) * 60 + (s || 0)}.${parseInt(
					(ms || 0).toString().substring(0, 3),
					10
				)}`
			)
		);
	};

	const onKeyPress = (
		e: React.KeyboardEvent<HTMLInputElement>,
		focus: 'ms' | 'm' | 's'
	) => {
		if (e.key === 'Tab') {
			e.stopPropagation();
			e.preventDefault();
			if (focus === 'm') {
				setFocus('s');
			} else if (focus === 's') {
				setFocus('ms');
			} else if (focus === 'ms') {
				setFocus('m');
			}
		}

		if (e.key === 'Enter') {
			onSubmit();
		}
	};

	useEffect(() => {
		const time = getTime(value);
		setMs(time.ms);
		setS(time.s);
		setM(time.m);
	}, [value]);

	useEffect(() => {
		if (focus === 'm') {
			mRef.current?.focus();
		} else if (focus === 's') {
			sRef.current?.focus();
		} else if (focus === 'ms') {
			msRef.current?.focus();
		}
	}, [focus]);

	return (
		<div className="InputNumberWrapper" onBlur={() => setFocus(null)}>
			<input
				type="number"
				value={m ? m : m === 0 ? 0 : ''}
				ref={mRef}
				min={0}
				max={60}
				maxLength={2}
				placeholder={'--'}
				className={classnames('InputNumberValue', 'InputNumberValue2', {
					focus: focus === 'm',
				})}
				onChange={e => setM(parseInt(e.currentTarget.value, 10))}
				onKeyDown={e => onKeyPress(e, 'm')}
				onBlur={() => {
					setFocus(null);
					onSubmit();
				}}
				onFocus={() => setFocus('m')}
			/>
			.
			<input
				type="number"
				value={s ? s : s === 0 ? 0 : ''}
				ref={sRef}
				min={0}
				max={60}
				maxLength={2}
				placeholder={'--'}
				className={classnames('InputNumberValue', 'InputNumberValue2', {
					focus: focus === 's',
				})}
				onChange={e => setS(parseInt(e.currentTarget.value, 10))}
				onKeyDown={e => onKeyPress(e, 's')}
				onBlur={() => {
					setFocus(null);
					onSubmit();
				}}
				onFocus={() => setFocus('s')}
			/>
			:
			<input
				type="number"
				value={ms ? ms : ms === 0 ? 0 : ''}
				ref={msRef}
				min={0}
				max={999}
				minLength={3}
				maxLength={3}
				placeholder={'---'}
				className={classnames('InputNumberValue', 'InputNumberValue3', {
					focus: focus === 'ms',
				})}
				onChange={e => {
					const currentValue = parseInt(e.currentTarget.value, 10);
					if (!currentValue && currentValue !== 0) {
						setMs(null);
					}
					if (currentValue >= 0 && currentValue < 1000) {
						setMs(parseInt(e.currentTarget.value, 10));
					}
				}}
				onKeyDown={e => onKeyPress(e, 'ms')}
				onBlur={() => {
					setFocus(null);
					onSubmit();
				}}
				onFocus={() => setFocus('ms')}
			/>
		</div>
	);
};
